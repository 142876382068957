import {
  QuestionMark,
  ExpandMore,
  CheckCircle,
  Cancel,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import { URL } from "../../../../tools/url";
import ConfirmDeletePopup from "../../../popups/admin/ConfirmDeletePopup";
import ScenarioConditionsDetailsPopup from "./ScenarioConditionDetailsPopup";
import useErrorPopup from "../../../../tools/hooks/showError";

const ScenarioConditionInList = ({
  scenarioCondition,
  updateScenarioCondition,
  saveScenarioCondition,
  allScenarioStates,
  saving,
}) => {
  const [outcomesToScenarios, setOutcomesToScenarios] = useState(null);

  const [newOutcomeState, setNewOutcomeState] = useState(0);
  const [newOutcomeOutcome, setNewOutcomeOutcome] = useState("");
  const [editingNew, setEditingNew] = useState(false);
  const [savingNew, setSavingNew] = useState(false);

  const [scenarioStates, setScenarioStates] = useState([]);

  const [editPopupOpen, setEditPopupOpen] = useState(false);

  const [deleteScenarioConditionPopup, setDeleteScenarioConditionPopup] =
    useState(false);

  const [showError, ErrorPopupComponent] = useErrorPopup();

  const deleteScenarioCondition = async () => {
    try {
      await axios.post(`${URL}/api/ceDeleteScenarioCondition/`, {
        scenarioConditionId: scenarioCondition.id,
      });
      updateScenarioCondition(scenarioCondition, false, true);
    } catch (err) {
      console.error(err);
      showError("Couldn't delete scenario conditon");
    }
  };

  useEffect(() => {
    if (allScenarioStates && outcomesToScenarios) {
      setScenarioStates(
        allScenarioStates.filter(
          (i) =>
            !outcomesToScenarios.some(
              (outcome) => outcome.target_state_id === i.id
            )
        )
      );
    }
  }, [allScenarioStates, outcomesToScenarios]);

  useEffect(() => {
    if (scenarioCondition) setOutcomesToScenarios(scenarioCondition.outcomes);
  }, [scenarioCondition]);

  const saveNewOutcome = async () => {
    if (newOutcomeState && newOutcomeOutcome) {
      setSavingNew(true);
      saveScenarioCondition({
        ...scenarioCondition,
        outcomes: [
          ...scenarioCondition.outcomes,
          {
            target_state_id: newOutcomeState,
            outcome: newOutcomeOutcome,
            parent_condition_id: scenarioCondition.id,
          },
        ],
      });
      setSavingNew(false);
      setEditingNew(false);
      setNewOutcomeState(0);
    }
  };

  const deleteOutcome = async (outcome) => {
    await axios.post(`${URL}/api/ceDeleteScenarioConditionOutcome/`, {
      scenarioConditionOutcomeId: outcome.id,
    });
    updateScenarioCondition({
      ...scenarioCondition,
      outcomes: scenarioCondition.outcomes.filter(
        (o) => o.id !== outcome.id
      ),
    });
  };

  return (
    <>
      <Accordion
        className="staff-course-in-list"
        style={{ margin: "10px 0", background: "#7ee2b8" }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <div className="staff-course-in-list-name">
            <QuestionMark />
            SCENARIO CONDITION: {scenarioCondition.name}
            <div
              style={{
                marginInlineStart: "5px",
                display: "flex",
                alignItems: "center",
              }}
              title={scenarioCondition.is_active ? "Active" : "Not active"}
            >
              {scenarioCondition.is_active ? (
                <CheckCircle style={{ fontSize: "15px" }} />
              ) : (
                <Cancel style={{ fontSize: "15px" }} />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="staff-course-in-list-details">
            <div className="staff-course-in-list-edit-button-wrapper">
              <button
                className="prompt-generation-button"
                onClick={() => setEditPopupOpen(true)}
              >
                EDIT DETAILS FOR SCENARIO CONDITION "
                {(scenarioCondition?.name ?? "").toUpperCase()}"
              </button>
            </div>
          </div>
          <div className="staff-course-in-list-units">
            <div
              style={{
                margin: "15px 0 5px 0",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              OUTCOMES
            </div>
            {outcomesToScenarios ? (
              outcomesToScenarios.length ? (
                <div>
                  {(outcomesToScenarios || []).map((item) => (
                    <div
                      key={item.id}
                      style={{
                        backgroundColor: "#F5CD47",
                        height: "35px",
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "bold",
                        padding: "5px 10px",
                        margin: "5px 0",
                      }}
                      className="staff-course-in-list"
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontWeight: "bold",
                          flex: 1,
                        }}
                      >
                        {item.outcome ? `"${item.outcome}"` : ""}
                        {" → "}
                        <span
                          style={{
                            padding: "2px 7px",
                            borderRadius: "5px",
                            backgroundColor: "#fec195",
                            fontWeight: "bold",
                            margin: "7px",
                            boxShadow: "#00000050 1px 1px 3px 1px",
                          }}
                        >
                          {allScenarioStates.find(
                            (s) => s.id === item.target_state_id
                          )?.name || ""}
                        </span>
                      </div>
                      <button
                        className="prompt-generation-button"
                        style={{
                          margin: 0,
                          padding: "5px 10px",
                          background: "#fa7d5f",
                          border: "1px solid #282c34",
                          color: "#282c34",
                        }}
                        onClick={() => deleteOutcome(item)}
                      >
                        DELETE OUTCOME
                      </button>
                    </div>
                  ))}
                </div>
              ) : (
                <i>No outcomes</i>
              )
            ) : (
              <>loading...</>
            )}
            {scenarioStates.length ? (
              <div
                className="staff-course-in-list"
                style={{
                  margin: "10px 0",
                  background: "#F5CD47",
                  display: "flex",
                  alignItems: "center",
                  height: "55px",
                  padding: "0 15px",
                }}
              >
                {editingNew ? (
                  <div
                    className="staff-course-in-list-name"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {/* image? */}
                    <div
                      style={{ flex: 1, display: "flex", alignItems: "center" }}
                    >
                      <div className="input-in-listed-scenario">
                        <label htmlFor="outcome">Outcome text</label>
                        <input
                          htmlFor="outcome"
                          value={newOutcomeOutcome}
                          onChange={(e) => setNewOutcomeOutcome(e.target.value)}
                          disabled={savingNew}
                        ></input>
                      </div>
                      {" → "}
                      <select
                        className="rectangle-object"
                        placeholder="Select state for outcome..."
                        value={newOutcomeState}
                        onChange={(e) =>
                          setNewOutcomeState(Number(e.target.value))
                        }
                        disabled={savingNew}
                        style={{ marginLeft: "10px" }}
                      >
                        <option value={0}>-- choose a state --</option>
                        {scenarioStates.map((state) => (
                          <option key={state.id} value={state.id}>
                            {state.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {savingNew ? (
                      <div
                        style={{
                          width: "80px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          className="loader prompt-generation-loader"
                          style={{ height: "35px" }}
                        >
                          <div id="bar1" className="bar"></div>
                          <div id="bar2" className="bar"></div>
                          <div id="bar3" className="bar"></div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <button
                          className="prompt-generation-button"
                          style={{
                            margin: "0 5px 0 0",
                            padding: "5px 10px",
                            background: !(newOutcomeState && newOutcomeOutcome)
                              ? "#ccc"
                              : "#0ae5a1",
                            border: "1px solid #282c34",
                            color: "#282c34",
                            cursor: !(newOutcomeState && newOutcomeOutcome)
                              ? "not-allowed"
                              : "pointer",
                          }}
                          disabled={!(newOutcomeState && newOutcomeOutcome)}
                          onClick={saveNewOutcome}
                        >
                          SAVE
                        </button>
                        <button
                          className="prompt-generation-button"
                          style={{
                            margin: 0,
                            padding: "5px 10px",
                            background: "#fa7d5f",
                            border: "1px solid #282c34",
                            color: "#282c34",
                          }}
                          onClick={() => {
                            setEditingNew(false);
                            setNewOutcomeState(0);
                          }}
                        >
                          CANCEL
                        </button>
                      </>
                    )}
                  </div>
                ) : (
                  <button
                    className="prompt-generation-button"
                    style={{
                      margin: 0,
                      padding: "5px 10px",
                    }}
                    onClick={() => setEditingNew(true)}
                  >
                    ADD A NEW OUTCOME
                  </button>
                )}
              </div>
            ) : (
              <div>
                <i>No available states, add states above</i>
              </div>
            )}
          </div>
          <div className="staff-course-in-list-edit-button-wrapper">
            <button
              className="prompt-generation-button"
              onClick={() => setDeleteScenarioConditionPopup(true)}
              style={{
                backgroundColor: "#fa7d5f",
                color: "#282c34",
                fontWeight: "bold",
              }}
            >
              DELETE CONDITION "{(scenarioCondition?.name ?? "").toUpperCase()}"
            </button>
          </div>
        </AccordionDetails>
      </Accordion>
      <ScenarioConditionsDetailsPopup
        close={() => setEditPopupOpen(false)}
        condition={scenarioCondition}
        open={editPopupOpen}
        save={(data) => saveScenarioCondition(data)}
        saving={saving}
      />
      <ConfirmDeletePopup
        open={deleteScenarioConditionPopup}
        confirmDelete={deleteScenarioCondition}
        close={() => setDeleteScenarioConditionPopup(false)}
      >
        Are you sure you want to delete scenario condition "
        {scenarioCondition?.name || ""}"?
      </ConfirmDeletePopup>
      {ErrorPopupComponent}
    </>
  );
};

export default ScenarioConditionInList;
