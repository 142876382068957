export const UNITYFILES = {
    loaderUrl: "avatarBuild/UnityBuild.loader.js",
    frameworkUrl: "avatarBuild/UnityBuild.framework.js",
    dataUrl: "avatarBuild/UnityBuild.data",
    codeUrl: "avatarBuild/UnityBuild.wasm",
}

export const ADMINMODE = false

export const URL = process.env.REACT_APP_URL
export const DOMAIN = process.env.REACT_APP_DOMAIN

// export const UNITYFILES = {
//     loaderUrl: "avatarBuild/UnityBuild_dev.loader.js",
//     frameworkUrl: "avatarBuild/UnityBuild_dev.framework.js.br",
//     dataUrl: "avatarBuild/UnityBuild_dev.data.br",
//     codeUrl: "avatarBuild/UnityBuild_dev.wasm.br",
// }