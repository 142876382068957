import {
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Chip,
} from "@mui/material";
import "../../style/userInfo.css";

function UserInfoForm({ questions, formData, setFormData }) {
  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <form className="user-info-form">
      {questions.map((question) => {
        const {
          id,
          name,
          question: questionText,
          type,
          enum_choices,
          multi_pick_config,
        } = question;

        if (type === "TEXT") {
          return (
            <div key={id}>
              <FormLabel>{questionText}</FormLabel>
              <TextField
                fullWidth
                value={!formData ? "loading..." : formData[name]?.value ?? ""}
                onChange={(e) =>
                  handleInputChange(name, { id: id, value: e.target.value })
                }
                disabled={!formData}
              />
            </div>
          );
        }

        if (type === "DATE") {
          return (
            <div key={id}>
              <FormLabel>{questionText}</FormLabel>
              <TextField
                fullWidth
                type="date"
                InputLabelProps={{ shrink: true }}
                value={formData ? formData[name]?.value ?? "" : ""}
                onChange={(e) =>
                  handleInputChange(name, { id: id, value: e.target.value })
                }
                disabled={!formData}
              />
            </div>
          );
        }

        if (type === "ENUM") {
          const options = JSON.parse(enum_choices || "[]");
          return (
            <div key={id}>
              <FormLabel>{questionText}</FormLabel>
              <RadioGroup
                value={formData ? formData[name]?.value ?? "" : ""}
                onChange={(e) =>
                  handleInputChange(name, { id: id, value: e.target.value })
                }
                disabled={!formData}
              >
                {options.map((option) => (
                  <FormControlLabel
                    key={option}
                    value={option}
                    control={<Radio />}
                    label={option}
                  />
                ))}
              </RadioGroup>
            </div>
          );
        }

        if (type === "MULTI_PICK") {
          const { range, values } = JSON.parse(
            multi_pick_config || '{"range": [1, 1], "values": []}'
          );

          const min = range[0];
          const max = range[1];

          const handleChipClick = (value) => {
            const selectedValues = JSON.parse(
              formData
                ? formData[name]?.value
                  ? formData[name]?.value.length
                    ? formData[name]?.value
                    : "[]"
                  : "[]"
                : "[]"
            );
            if (selectedValues.includes(value)) {
              handleInputChange(name, {
                id: id,
                value: JSON.stringify(
                  selectedValues.filter((v) => v !== value)
                ),
              });
            } else if (selectedValues.length < max) {
              handleInputChange(name, {
                id: id,
                value: JSON.stringify([...selectedValues, value]),
              });
            }
          };

          return (
            <div key={id}>
              <FormLabel>
                {questionText} (Select {min} to {max})
              </FormLabel>
              <div className="chip-container">
                {values.map((value) => (
                  <Chip
                    key={value}
                    label={value}
                    clickable
                    color={
                      formData
                        ? formData[name]?.value?.includes(value)
                          ? "primary"
                          : "default" ?? "default"
                        : "default"
                    }
                    onClick={() => handleChipClick(value)}
                    className={
                      formData
                        ? formData[name]?.value?.includes(value)
                          ? "Mui-selected"
                          : ""
                        : ""
                    }
                    disabled={!formData}
                  />
                ))}
              </div>
            </div>
          );
        }

        return null;
      })}
    </form>
  );
}

export default UserInfoForm;
