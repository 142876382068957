//packages
import React from "react";
import { useNavigate } from "react-router-dom";
//style
import '../../style/endChatPopup.css';
//tools
import { vh, vw } from "../../tools/screen"
import Button from "../button";


const EndChatPopup = ({ info, setReset, setEndChatPopup }) => {
    const navigate = useNavigate();


    let goHome = async () => {
        if (localStorage.getItem("courseId")) navigate(`/course/${localStorage.getItem("courseId")}`);
        else navigate("/");
    }
    return (<>
        {info?.status === 'Failure' ?
            <img src="/images/chatEndX.png" className="statusIcon" style={{ marginLeft: vw(50) - vh(12.5) }} alt="X" /> :
            <img src="/images/chatEndV.png" className="statusIcon" style={{ marginLeft: vw(50) - vh(12.5) }} alt="V" />
        }
        <div className="endPopupContainer" style={{ zIndex: 4 }}>
            <div className="back" onClick={() => { goHome() }} style={{ marginTop: vh(2) }}>
                <img src="/images/back.png" alt="back" style={{ height: vh(2) }} />
                <h2>Home</h2>
            </div>

            <div className="back" onClick={() => { setEndChatPopup(false) }} style={{ alignSelf: 'flex-end', flexDirection: 'row', position: 'absolute', marginTop: vh(3) }}>
                <img src="/images/x_button.png" alt="back" style={{ height: vh(2), marginRight: vw(2) }} />
            </div>

            {info?.status === 'Failure' ?
                <h2 className="endTitle">Conversation ended</h2> :
                <h2 className="endTitle">Well Done!</h2>}

            <h2 className="endText">{info?.message}</h2>
            <div className="centerButton" style={{}}>
                <Button
                    text='Restart conversation'
                    style={{ backgroundColor: '#262D3B' }}
                    textStyle={{
                        color: 'white',
                        fontFamily: 'Assistant',
                        fontWeight: '600',
                        fontSize: vh(3),
                    }}
                    alt='start'
                    icon='/images/playWhite.png'
                    onClick={() => { setReset(true) }}
                    />
                    </div>
                {/* <Button
                    text='Talk to Skill Coach'
                    style={{ backgroundColor: '#ECECEC' }}
                    textStyle={{
                        color: '#262D3B',
                        fontFamily: 'Assistant',
                        fontWeight: '400',
                        fontSize: vh(3),
                        paddingLeft: vw(1.25),
                        paddingRight: vw(1.25),
                    }}
                    alt='start'
                    onClick={() => { }}
                />

            <div className="infoEndChat" style={{ opacity: 0.3 }}>
                <img src="/images/information.png" alt="info" />
                <h2>Get feedback on the conversation</h2>
            </div> */}

            </div >
        </>
        );
};

        export default EndChatPopup;
