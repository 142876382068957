import {
  Box,
  Divider,
  Link,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Fragment } from "react";

const SIDEBAR_ITEMS = [
  {
    name: "Home",
    link: "/admin",
  },
  {
    name: "Courses",
    link: "/admin/courses",
  },
  {
    name: "Classification questions",
    link: "/admin/classification-questions",
  },
  {
    name: "Skills",
    link: "/admin/skills",
  },
  {
    name: "KPIs",
    link: "/admin/KPIs",
  },
  {
    name: "LLM management",
    link: "/admin/llm-management",
  },
  {
    name: "Conversation history",
    link: "/admin/conversation-history",
  },
  {
    name: "User info questions",
    link: "/admin/user-info-questions",
  },
];

const AdminSidebar = ({ currentLocation }) => {
  return (
    <div className="admin-sidebar">
      <Box>
        <List>
          {SIDEBAR_ITEMS.map((item) => (
            <Fragment key={item.name}>
              <ListItemButton
                disabled={item.name === currentLocation}
                component={Link}
                href={item.link}
              >
                <ListItemText primary={item.name} />
              </ListItemButton>
              <Divider />
            </Fragment>
          ))}
        </List>
      </Box>
    </div>
  );
};

export default AdminSidebar;
