import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../components/header";
import { vh, vw } from "../tools/screen";
import Course from "../components/course/Course";
import { useEffect } from "react";

const CoursePage = () => {
  const navigate = useNavigate();
  const url_params = useParams();

  useEffect(() => {
    if (url_params.id) localStorage.setItem("courseId", url_params.id);
  }, [url_params]);

  return (
    <div style={{ width: vw(100), height: vh(100), overflow: "hidden" }}>
      <PageHeader />
      <div
        className="back"
        onClick={() => {
          localStorage.removeItem("courseId");
          navigate("/");
        }}
      >
        <img src="/images/back.png" alt="back" style={{ height: vh(2) }} />
        <h2>Back to courses</h2>
      </div>
      {url_params?.id ? <Course courseId={url_params.id} /> : <></>}
    </div>
  );
};

export default CoursePage;
