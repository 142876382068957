const admin_enums = {
  Speaker: {
    USER: "U",
    BOT: "B",
  },
  LLMName2: {
    GPT_3_5_TURBO: "GPT_3_5_TURBO",
    GPT_4o_MINI: "GPT_4o_MINI",
    GPT_4_TURBO: "GPT_4_TURBO",
    GPT_4o: "GPT_4o",
    MIXTRAL_8X7B: "MIXTRAL_8X7B",
    MIXTRAL_8X22B: "MIXTRAL_8X22B",
  },
};

/**
 * @param {string} enumString - the string describing the enum in the ```admin_enums``` object, format: ```"EnumName.ENUM_VALUE"```
 * @returns the value of the enum
 */
const getServerEnumValue = (enumString) => {
  try {
    if (typeof enumString !== "string")
      throw new Error(
        `Error parsing enum: enumString must be type string, got ${typeof enumString}`
      );
    const splitEnumString = enumString.split(".");
    if (splitEnumString.length !== 2)
      throw new Error(
        `Error parsing enum: enumString must have format "EnumName.ENUM_VALUE", got "${enumString}"`
      );
    const enumObj = admin_enums[splitEnumString[0]];
    if (!enumObj)
      throw new Error(
        `Error parsing enum: admin_enums object does not include key ${enumObj}`
      );
    const enumVal = enumObj[splitEnumString[1]];
    if (!enumVal)
      throw new Error(
        `Error parsing enum: enum ${splitEnumString[0]} does not include key ${splitEnumString[1]}`
      );
    return enumVal;
  } catch (err) {
    console.error(err);
    return "";
  }
};

/**
 * This recursive function recieves an encoded JSON array or object from the server, in which the enums are formatted like this:
 *
 * ```{"__enum__": "EnumName.ENUM_VALUE"}```
 *
 * @param {*} obj - the JSON object
 *
 * @returns the decoded JSON, in which the enum objects are replaced by their values, as defined in the ```admin_enums``` object
 *
 * @example ```
 * decodeJSONFromServer([{ "a": {"__enum__": "Speaker.USER"}, "b": [{"c": {"__enum__": "LLMName2.GPT_3_5_TURBO"}}]}]);
 * // -> [{ "a": "U", "b": [{"c": "GPT_3_5_TURBO"}]}]
 * ```
 */
const decodeJSONFromServer = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    return obj; // Base case: return non-objects as is
  }

  // Handle arrays
  if (Array.isArray(obj)) {
    return obj.map((item) => decodeJSONFromServer(item));
  }

  // Handle objects
  let decodedObj = {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (key === "__enum__") {
        decodedObj = getServerEnumValue(obj[key]);
      } else if (
        typeof obj[key] === "object" &&
        obj[key] !== null &&
        "__enum__" in obj[key]
      ) {
        decodedObj[key] = getServerEnumValue(obj[key].__enum__);
      } else {
        decodedObj[key] = decodeJSONFromServer(obj[key]);
      }
    }
  }
  return decodedObj;
};

export { admin_enums, getServerEnumValue, decodeJSONFromServer };
