import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PageHeader from "../header";
import Unit from "./Unit";
import CourseSkeleton from "../skeletons/courseSk";
import chat from "../../stores/chat.store";
import { URL } from "../../tools/url";
import { vh, vw } from "../../tools/screen";
import { Step, Stepper } from "@mui/material";

const Course = ({ courseId }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [topics, setTopics] = useState([]);
  const [devMode, setDevMode] = useState(false);
  const [userPlacement, setUserPlacement] = useState([0, 0]);

  useEffect(() => {
    const fetchCourseData = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(`${URL}/api/getCourseContent`, {
          params: { sessionId: chat.chatSettings.sessionId, courseId },
        });
        chat.setSkills(data.skills);
        setTopics(initializeTopics(data.topics));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching course data:", error);
      }
    };

    fetchCourseData();
    setDevMode(localStorage.getItem("is_admin") === "true");
  }, []);

  const initializeTopics = (topicsData) => {
    return topicsData.map((topic) => ({
      ...topic,
      status: "done",
      items: topic.items.map((conv) => ({ ...conv, status: "done" })),
    }));
  };

  return (
    <div
      className="progressBar"
      style={
        loading
          ? {
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }
          : {}
      }
    >
      {loading ? (
        <CourseSkeleton />
      ) : (
        <div>
          {topics.length ? (
            <Stepper>
              {topics.map((topic, index) => {
                return (
                  <Step
                    completed={topic.status === "done"}
                    key={index}
                    sx={{ p: 0 }}
                  >
                    <Unit
                      onClick={() => setUserPlacement([index, 0])}
                      topic={topic}
                      index={index}
                      devMode={devMode}
                      navigate={navigate}
                      userPlacement={userPlacement}
                      setUserPlacement={setUserPlacement}
                    />
                  </Step>
                );
              })}
            </Stepper>
          ) : (
            <h2>coming soon...</h2>
          )}
        </div>
      )}
    </div>
  );
};

export default Course;
