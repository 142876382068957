//packages
import React, { useEffect, useState } from "react";
import axios from "axios";
//style
import '../../style/reviewPopup.css';
//consts
import { URL } from "../../tools/url";
//stores
import chat from "../../stores/chat.store";

const ReviewPopup = ({ close, userTurn }) => {

    const [reviewDropdown, setReviewDropdown] = useState(false);
    const [reviewMsg, setReviewMsg] = useState(false);
    const [search, setSearch] = useState('');
    const [skills, setSkills] = useState([]);
    const [skillsDisplay, setSkillsDisplay] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [validation, setValidation] = useState('');




    // const skills = ['Start conversation', 'Trading Information', 'Handling Arguments', 'dont interview', 'dont brag', 'Turning someone down', 'say hi', 'find intrest', 'dont brag', 'start conversation', 'say hi', 'find intrest', 'dont brag', 'Start conversation', 'Trading Information', 'Handling Arguments', 'dont interview', 'dont brag', 'Turning someone down', 'say hi', 'find intrest', 'dont brag', 'start conversation', 'say hi', 'find intrest', 'dont brag', 'Turning someone down', 'say hi', 'find intrest', 'dont brag', 'start conversation', 'say hi', 'find intrest', 'dont brag', 'Start conversation', 'Trading Information', 'Handling Arguments', 'dont interview', 'dont brag', 'Turning someone down', 'say hi', 'find intrest', 'dont brag', 'start conversation', 'say hi', 'find intrest', 'dont brag']
    const colors = ['#D5E2E0', '#F8EAEC', '#CAE0E4', '#F7DDD9', '#F7E6DA', '#EEF3E9', '#C8C7D6', '#F9E0E2', '#E3E9DD', '#C4DBD9', '#E4E4EC', '#F0D7DF', '#FFF3EB']
    const reviewOptions = ['Cue', 'Feedback']

    useEffect(() => {
        let skills = localStorage.getItem('skills');
        setSkills(JSON.parse(skills))
    }, [])

    function sortSearch(currList, regex) {
        setSkillsDisplay(
            [...currList].sort((a, b) => {
                return a.skillName.search(regex) < b.skillName.search(regex) ? -1 : 1;
            })
        );
    }
    useEffect(() => {
        if (skills) {
            if (/^\s*$/.test(search)) { // if search is empty, show all users
                setSkillsDisplay([...skills]);
                return;
            }
            const regex = new RegExp(search, 'ig');
            let newList = [];
            for (const item of skills)
                if (new RegExp(search, 'ig').test(item.skillName))
                    newList.push(item);
            sortSearch(newList, regex);
        }
    }, [search, skills]);



    const handleFeedbackReview = async () => {
        if (!reviewMsg || selectedSkills.length === 0) {
            setValidation('please fill in all of the info')
        }
        else {


            let sendAllSkills = selectedSkills.map((skill) => {
                if (reviewMsg === 'cue') {
                    return ({ skillId: skill, cueExpected: true })
                }
                else {
                    return ({ skillId: skill, posFeedbackExpected: true })
                }
            })
            //closes the popup
            close(false);
            await axios.post(`${URL}/api/reportSkill/`,
                {
                    'sessionId': chat.chatSettings.sessionId,
                    'userTurnId': userTurn,
                    'skills': sendAllSkills
                });
        }
    }

    const handleSelectedSkills = (skillId) => {
        let updatedSkills = [...selectedSkills];
        if (selectedSkills.includes(skillId)) {
            updatedSkills = selectedSkills.filter(id => id !== skillId)
            setSelectedSkills(updatedSkills)
        }
        else {
            updatedSkills.push(skillId)
            setSelectedSkills(updatedSkills)
        }
    }
    return (
        <div className="popupContainer">
            <img
                onClick={() => { close(false); }}
                className="close"
                src="/images/x_button.png"
                alt="x"
            />
            <h2 className="revTitle">what would you like to add?</h2>

            <div className="dropDownContainer">
                <div
                    className="reviewOpt"
                    onClick={() => { setReviewDropdown(!reviewDropdown) }}
                >
                    <h2>
                        {reviewMsg || 'opt.'}
                    </h2>
                    <img
                        src="/images/dropdown.png"
                        alt="dropdown"
                    />
                </div>
                {reviewOptions.map((opt) => {
                    return (
                        <>
                            {reviewDropdown ?
                                <div
                                    key={opt}
                                    className="reviewOpt"
                                    style={reviewMsg === opt ? { backgroundColor: '#d6d6d6' } : {}}
                                    onClick={() => {
                                        setReviewMsg(opt); setReviewDropdown(false)
                                    }}>
                                    <h2>
                                        {opt}
                                    </h2>
                                </div>
                                :
                                <></>}
                        </>

                    )
                })}
            </div>
            <div className="searchFeedbacks">
                <textarea
                    type="text"
                    aria-multiline={false}
                    wrap="off"
                    placeholder={'search...'}
                    value={search}
                    onChange={(event) => {
                        setSearch(event.target.value);
                    }}
                />
                <img
                    src="/images/search.png"
                    alt="search"
                />

            </div>
            <div className="skillsReview">
                {skillsDisplay.map((skill, index) => {
                    let col = colors.length - 1
                    let colorDisplayId = index - Math.floor(index / col) * col;
                    return (
                        <div
                            onClick={() => { handleSelectedSkills(skill.skillId) }}
                            key={index}
                            style={selectedSkills.includes(skill.skillId) ? { backgroundColor: '#10A0A0' } : { backgroundColor: colors[colorDisplayId] }}
                            className="skill">
                            <h2
                                style={selectedSkills.includes(skill.skillId) ? { color: 'white' } : {}}
                            >
                                {skill.skillName}
                            </h2>
                        </div>
                    )
                })}
            </div>
            {validation ? <h2 className="validation">{validation}</h2> : <></>}
            <div onClick={() => { handleFeedbackReview() }} className="sendReview">
                <h2>Send review</h2>
            </div>
        </div>
    );
};

export default ReviewPopup;
