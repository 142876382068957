import { useState, useEffect, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Collapse,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import { URL } from "../../../tools/url";
import axios from "axios";
import useErrorPopup from "../../../tools/hooks/showError";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

const CONVS_PER_PAGE = 10;

const ConversationHistoryTable = () => {
  const [dataFromServer, setDataFromServer] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const [userOptions, setUserOptions] = useState([]);
  const [scenarioOptions, setScenarioOptions] = useState([]);

  const [selectedUser, setSelectedUser] = useState(0);
  const [selectedScenario, setSelectedScenario] = useState(0);

  const [showError, ErrorPopupComponent] = useErrorPopup();

  const fetchRows = async (filters = {}, clear = false) => {
    setLoading(true);
    try {
      const { data } = await axios.post(
        `${URL}/api/adminGetListOfUserScenarios/`,
        {
          ...filters,
        }
      );
      console.log(
        "user scenarios from server",
        data.user_scenarios.map((i) => JSON.parse(i))
      );
      if (clear)
        // filter changed, override previous data
        setDataFromServer(data.user_scenarios.map((i) => JSON.parse(i)));
      else
        setDataFromServer((prev) => [
          ...prev,
          ...data.user_scenarios.map((i) => JSON.parse(i)),
        ]);
    } catch (err) {
      console.error(err);
      showError("Couldn't get conversation history");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers();
    fetchScenarios();
  }, []);

  const handleFilteredFetch = (onPagination = false, clear = false) => {
    let filters = {};
    if (onPagination) {
      // fetch next conversations that come before the earliest conversation fetched so far
      const earliestDatetime = dataFromServer
        .map((item) => item.start_time_stamp)
        .reduce((earliestDate, currentDate) => {
          const earlyDate = new Date(earliestDate);
          const currDate = new Date(currentDate);
          return currDate < earlyDate ? currentDate : earliestDate;
        });
      filters.before_datetime = new Date(earliestDatetime).toISOString();
    } // fetch the newest conversations (earliest date: 24 hours in the future - workaround that solves timezone issues)
    else
      filters.before_datetime = new Date(Date.now() + 86400000).toISOString();
    if (selectedUser) filters.filter_by_user_id = selectedUser;
    if (selectedScenario) filters.filter_by_scenario_id = selectedScenario;
    fetchRows(filters, clear);
  };

  const handleChangePage = (_event, newPage) => {
    handleFilteredFetch(true);
    setPage(newPage);
  };

  // Paginate data
  const paginatedData = useMemo(
    () =>
      (dataFromServer ?? []).slice(
        page * CONVS_PER_PAGE,
        page * CONVS_PER_PAGE + CONVS_PER_PAGE
      ),
    [dataFromServer]
  );

  const fetchUsers = async () => {
    try {
      const { data } = await axios.get(`${URL}/api/adminGetListOfAllUsers/`);
      setUserOptions(
        data?.users.map((user) => {
          const parsedUser = JSON.parse(user);
          return (
            <option key={parsedUser.id} value={parsedUser.id}>
              {parsedUser.username}
            </option>
          );
        })
      );
    } catch (err) {
      console.error(err);
      showError("Couldn't get users");
    }
  };

  const fetchScenarios = async () => {
    try {
      const { data } = await axios.get(
        `${URL}/api/adminGetListOfAllScenarios/`
      );
      setScenarioOptions(
        data?.scenarios.map((scenario) => {
          const parsedScenario = JSON.parse(scenario);
          return (
            <option key={parsedScenario.id} value={parsedScenario.id}>
              {parsedScenario.name}
            </option>
          );
        })
      );
    } catch (err) {
      console.error(err);
      showError("Couldn't get scenarios");
    }
  };

  useEffect(() => {
    handleFilteredFetch(false, true);
  }, [selectedUser, selectedScenario]);

  const Row = ({
    user,
    scenario,
    status,
    start_time_stamp,
    end_conv_state,
    clean_text,
    rich_text,
  }) => {
    const [open, setOpen] = useState(false);
    return (
      <>
        <TableRow>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              className="prompt-generation-expand-table-row"
              title={open ? "Collapse" : "Show conversation details"}
              onClick={(e) => {
                e.stopPropagation();
                setOpen(!open);
              }}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell>{user}</TableCell>
          <TableCell>{scenario}</TableCell>
          <TableCell>{status}</TableCell>
          <TableCell>{new Date(start_time_stamp).toLocaleString()}</TableCell>
          <TableCell>{end_conv_state}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Clean text
                </Typography>
                <pre className="prompt-generation-result-markdown conv-hist-markdown">
                  {clean_text}
                </pre>
                <br />
                <Typography variant="h6" gutterBottom component="div">
                  Rich text
                </Typography>
                <pre className="prompt-generation-result-markdown conv-hist-markdown">
                  {rich_text}
                </pre>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <>
      <div className="flexbox-container">
        <div className="filter-item" style={{ alignItems: "center" }}>
          <label
            className="filter-bar"
            style={{ width: "unset", margin: "0 15px 0 0" }}
          >
            Select user:
          </label>
          <select
            className="rectangle-object"
            name="selected_user"
            onChange={(e) => setSelectedUser(Number(e.target.value))}
            value={selectedUser}
            style={{
              width: "150px",
            }}
            disabled={!userOptions?.length}
          >
            <option value={0}>- All users -</option>
            {userOptions}
          </select>
        </div>
        <div className="filter-item" style={{ alignItems: "center" }}>
          <label
            className="filter-bar"
            style={{ width: "unset", margin: "0 15px 0 0" }}
          >
            Select scenario:
          </label>
          <select
            className="rectangle-object"
            name="selected_scenario"
            onChange={(e) => setSelectedScenario(Number(e.target.value))}
            style={{
              width: "150px",
            }}
            disabled={!scenarioOptions?.length}
          >
            <option value={0}>- All scenarios -</option>
            {scenarioOptions}
          </select>
        </div>
      </div>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>User</TableCell>
                <TableCell>Scenario</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Timestamp</TableCell>
                <TableCell>End state</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="loader prompt-generation-loader">
                      <div id="bar1" className="bar"></div>
                      <div id="bar2" className="bar"></div>
                      <div id="bar3" className="bar"></div>
                    </div>
                  </TableCell>
                </TableRow>
              ) : paginatedData.length ? (
                paginatedData.map((row) => <Row key={row.id} {...row} />)
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>No data</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={
            !dataFromServer?.length // if no data, only have 1 page
              ? 1
              : dataFromServer.length % CONVS_PER_PAGE // reached end, number not divisible by CONVS_PER_PAGE
              ? dataFromServer.length
              : !paginatedData.length
              ? dataFromServer.length
              : -1
          }
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={CONVS_PER_PAGE}
          rowsPerPageOptions={[]}
          labelDisplayedRows={() => null}
          labelRowsPerPage=""
        />
      </Paper>
      {ErrorPopupComponent}
    </>
  );
};

export default ConversationHistoryTable;
