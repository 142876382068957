import {
  Cancel,
  CheckCircle,
  Description,
  ExpandMore,
  OpenInNew,
  QuestionAnswer,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useErrorPopup from "../../../tools/hooks/showError";
import axios from "axios";
import { URL } from "../../../tools/url";
import ConfirmDeletePopup from "../../popups/admin/ConfirmDeletePopup";

const ScenarioInList = ({ scenario, updateScenario }) => {
  const navigate = useNavigate();
  const [deleteScenarioPopup, setDeleteScenarioPopup] = useState(false);

  const [showError, ErrorPopupComponent] = useErrorPopup();

  const deleteScenario = async () => {
    try {
      await axios.post(`${URL}/api/ceDeleteScenario/`, {
        scenarioId: scenario.id,
      });
      updateScenario({ id: scenario.id }, true);
    } catch (err) {
      console.error(err);
      showError("Couldn't delete scenario");
    }
  };
  return (
    <>
      <Accordion
        className="staff-course-in-list-lesson"
        style={{
          margin: "10px 0",
          background: "#ffd171",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <div className="staff-course-in-list-lesson-name">
            <QuestionAnswer /> SCENARIO: {scenario.name}{" "}
            <div
              style={{
                marginInlineStart: "5px",
                display: "flex",
                alignItems: "center",
              }}
              title={scenario.is_active ? "Active" : "Not active"}
            >
              {scenario.is_active ? (
                <CheckCircle style={{ fontSize: "15px" }} />
              ) : (
                <Cancel style={{ fontSize: "15px" }} />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="staff-course-in-list-scenario">
            <div
              className="staff-course-in-list-description"
              style={{
                backgroundColor: "#ffeeca",
                marginBottom: "15px",
              }}
            >
              <Description />{" "}
              {scenario.user_instructions ? (
                scenario.user_instructions
              ) : (
                <i>No user instructions</i>
              )}
            </div>
          </div>
          <div className="staff-course-in-list-edit-button-wrapper">
            <button
              className="prompt-generation-button"
              onClick={() =>
                navigate(`/admin/courses/edit-scenario/${scenario.id}`)
              }
              style={{ display: "flex", alignItems: "center" }}
            >
              EDIT SCENARIO "{(scenario?.name ?? "").toUpperCase()}"
              <OpenInNew style={{ marginInlineStart: "5px" }} />
            </button>
            <div className="staff-course-in-list-edit-button-wrapper">
              <button
                className="prompt-generation-button"
                onClick={() => setDeleteScenarioPopup(true)}
                style={{
                  backgroundColor: "#fa7d5f",
                  color: "#282c34",
                  fontWeight: "bold",
                }}
              >
                DELETE SCENARIO "{(scenario?.name ?? "").toUpperCase()}"
              </button>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <ConfirmDeletePopup
        open={deleteScenarioPopup}
        close={() => setDeleteScenarioPopup(false)}
        confirmDelete={deleteScenario}
      >
        Are you sure you want to delete this scenario?
      </ConfirmDeletePopup>
      {ErrorPopupComponent}
    </>
  );
};

export default ScenarioInList;
