import React, { useEffect, useState, useRef } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import "../../../style/llmManagement.css";
import useErrorPopup from "../../../tools/hooks/showError";
import axios from "axios";
import { URL } from "../../../tools/url";


const TestResultsPopup = ({ updateTree, showPopup, newModel }) => {
  const [testResults, setTestResults] = useState(null);
  const [resultList, setResultList] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [testsRepetitions, setTestsRepetitions] = useState(0);
  const [showError, ] = useErrorPopup();
  const pageRef = useRef(null);

  // create a table row data format based on the given data
  function createRow(name, stats) {
    const tests = stats["tests_num"];
    const oldLatency = Number(stats["previous_version"].avg_response_time_sec.toFixed(3));
    const oldCorrect = Number(stats["previous_version"].percent_correct.toFixed(3));
    const oldFalsePositives = Number(stats["previous_version"].percent_false_positives.toFixed(3));
    const oldFalseNegatives = Number(stats["previous_version"].percent_false_negatives.toFixed(3));
    const oldExceptions = Number(stats["previous_version"].percent_exceptions.toFixed(3));
    const newLatency = Number(stats["new_version"].avg_response_time_sec.toFixed(3));
    const newCorrect = Number(stats["new_version"].percent_correct.toFixed(3));
    const newFalsePositives = Number(stats["new_version"].percent_false_positives.toFixed(3));
    const newFalseNegatives = Number(stats["new_version"].percent_false_negatives.toFixed(3));
    const newExceptions = Number(stats["new_version"].percent_exceptions.toFixed(3));
    return { name, tests, oldLatency, oldCorrect, oldFalsePositives, oldFalseNegatives, oldExceptions,
              newLatency, newCorrect, newFalsePositives, newFalseNegatives, newExceptions };
  }

  // set the new value of number of tests repetitions
  const updateRepetitions = async (event) => {
    setTestsRepetitions(Number.isInteger(Number(event.target.value)) ? event.target.value : null);
  };

  // get the tests results from the server
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setSubmitted(true);
      const index = newModel.id.indexOf("_");
      const id = newModel.id.slice(0, index);
      const repetitions = Number(testsRepetitions).toFixed(0);
      const res = await axios.get(`${URL}/api/get_tests_results_comparison/?model_version=${id}&repetitions=${repetitions}`);
      setTestResults(res);
      updateTree();
      event.target.reset();
    } catch (err) {
      console.error(err);
      showError("Couldn't run tests");
    }
  }

  // scroll up on popup load
  useEffect(() => {
    pageRef?.current?.scrollIntoView({ block: 'end', behavior: "smooth" });
  }, []);

  // update the table when the results are ready
  useEffect(() => {
    if (testResults) {
      const testResultList = [];
      for (const [question, stats] of Object.entries(testResults.data)) {
        const row = createRow(question, stats);
        testResultList.push(row);
      }
      const calculateAvg = (property) => {
        const sum = testResultList.map((item) => item[property]).reduce((sum, i) => sum + i, 0)
        return Number(sum / testResultList.length).toFixed(3);
      };

      const tests = testResultList.map(({ tests }) => tests).reduce((sum, i) => sum + i, 0);
      const oldLatency = calculateAvg("oldLatency");
      const oldCorrect = calculateAvg("oldCorrect");
      const oldFalsePositives = calculateAvg("oldFalsePositives");
      const oldFalseNegatives = calculateAvg("oldFalseNegatives");
      const oldExceptions = calculateAvg("oldExceptions");
      const newLatency = calculateAvg("newLatency");
      const newCorrect = calculateAvg("newCorrect");
      const newFalsePositives = calculateAvg("newFalsePositives");
      const newFalseNegatives = calculateAvg("newFalseNegatives");
      const newExceptions = calculateAvg("newExceptions");
      const name = "Total";
      const sumRow = { name, tests, oldLatency, oldCorrect, oldFalsePositives, oldFalseNegatives, oldExceptions,
        newLatency, newCorrect, newFalsePositives, newFalseNegatives, newExceptions };
      testResultList.push(sumRow);
      console.log(testResultList);
      setResultList(testResultList);
    }
  }, [testResults]);

  return (
    <div className="testResultsPopup" ref={pageRef}>
      <h2 className="addModelHeader">Tests results</h2>
        { submitted ? (
          <>
            { resultList ? (
              <TableContainer component={Paper} sx={{ mt: 2, mb: 2 }}>
                <Table stickyHeader size="small" aria-label="results table">
                  <TableHead>
                    <TableRow sx={{ position: "sticky", top: 0, zIndex: 3 }}>
                      <TableCell align="center" colSpan={2}>
                        Classification Questions
                      </TableCell>
                      <TableCell align="center" colSpan={5}>
                        Original Model
                      </TableCell>
                      <TableCell align="center" colSpan={5}>
                        { newModel.label } (Selected Model)
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ position: "sticky", top: 37 }}>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="center">Number of Tests</TableCell>
                      <TableCell align="center">Latency (Sec)</TableCell>
                      <TableCell align="center">Correct (%)</TableCell>
                      <TableCell align="center">False Positives (%)</TableCell>
                      <TableCell align="center">False Negatives (%)</TableCell>
                      <TableCell align="center">Exceptions (%)</TableCell>
                      <TableCell align="center">Latency (Sec)</TableCell>
                      <TableCell align="center">Correct (%)</TableCell>
                      <TableCell align="center">False Positives (%)</TableCell>
                      <TableCell align="center">False Negatives (%)</TableCell>
                      <TableCell align="center">Exceptions (%)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { resultList && resultList.map((result) => (
                      <TableRow hover key={result.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0, fontWeight: 800 }}}>
                        <TableCell component="th" scope="row"
                                    sx={{ display: "block", maxWidth: 135, overflowWrap: "break-word" }}>
                                      {result.name}
                        </TableCell>
                        <TableCell align="left">{result.tests}</TableCell>
                        <TableCell align="left">{result.oldLatency}</TableCell>
                        <TableCell align="left">{result.oldCorrect}</TableCell>
                        <TableCell align="left">{result.oldFalsePositives}</TableCell>
                        <TableCell align="left">{result.oldFalseNegatives}</TableCell>
                        <TableCell align="left">{result.oldExceptions}</TableCell>
                        <TableCell align="left">{result.newLatency}</TableCell>
                        <TableCell align="left">{result.newCorrect}</TableCell>
                        <TableCell align="left">{result.newFalsePositives}</TableCell>
                        <TableCell align="left">{result.newFalseNegatives}</TableCell>
                        <TableCell align="left">{result.newExceptions}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : ( <h3 className="loadingText">Running tests, this might take a few minutes, please wait...</h3> ) }
          </>
        ) : (
          <div className="labelInput">
            <form onSubmit={handleSubmit} className="labelInput">
              <label className="runTestsLabel">Number of times to run each test:</label>
              <input onChange={updateRepetitions} type="number" className="runTestsInput" min="1" max="100"
                      name="testsRepetition" required />
              <div className="llmButtons">
                <button className="llmButton" type="submit" disabled={testsRepetitions ? false : true }>
                  Run tests
                </button>
              </div>
            </form>
          </div>
        ) }
      <div className="llmButtons">
        <button className="llmButtonRed" onClick={(e) => showPopup(false)}>Go back</button>
      </div>
    </div>
  );
};

export default TestResultsPopup;
