import { Cancel, CheckCircle, Crop75, ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import { URL } from "../../../../tools/url";
import ConfirmDeletePopup from "../../../popups/admin/ConfirmDeletePopup";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ScenarioStateDetailsPopup from "./ScenarioStateDetailsPopup";
import useErrorPopup from "../../../../tools/hooks/showError";

const ScenarioStateInList = ({
  scenarioState,
  updateScenarioState,
  saveScenarioState,
  allScenarioConditions,
  saving,
}) => {
  const [connectionsToConditions, setConnectionsToConditions] = useState(null);

  const [newConnection, setNewConnection] = useState(0);
  const [editingNew, setEditingNew] = useState(false);
  const [savingNew, setSavingNew] = useState(false);

  const [scenarioConditions, setScenarioConditions] = useState([]);

  const [editPopupOpen, setEditPopupOpen] = useState(false);

  const [deleteScenarioStatePopup, setDeleteScenarioStatePopup] =
    useState(false);

  const [showError, ErrorPopupComponent] = useErrorPopup();

  const deleteScenarioState = async () => {
    try {
      await axios.post(`${URL}/api/ceDeleteScenarioState/`, {
        scenarioStateId: scenarioState.id,
      });
      updateScenarioState(scenarioState, false, true);
    } catch (err) {
      console.error(err);
      showError("Couldn't delete scenario state");
    }
  };

  useEffect(() => {
    if (allScenarioConditions && connectionsToConditions) {
      setScenarioConditions(
        allScenarioConditions.filter(
          (i) =>
            !connectionsToConditions.some(
              (connection) => connection.target_condition_id === i.id
            )
        )
      );
    }
  }, [allScenarioConditions, connectionsToConditions]);

  useEffect(() => {
    if (scenarioState)
      setConnectionsToConditions(
        scenarioState.state_conditions?.sort((a, b) =>
          a.order < b.order ? -1 : 1
        ) || []
      );
  }, [scenarioState]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(connectionsToConditions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    saveScenarioState({
      ...scenarioState,
      state_conditions: items.map((item, index) => {
        return {
          ...item,
          order: index + 1,
        };
      }),
    });
  };

  const saveNewConnection = async () => {
    if (newConnection) {
      setSavingNew(true);
      saveScenarioState({
        ...scenarioState,
        state_conditions: [
          ...scenarioState.state_conditions,
          {
            target_condition_id: newConnection,
            parent_state_id: scenarioState.id,
            order: connectionsToConditions.length + 1,
          },
        ],
      });
      setSavingNew(false);
      setEditingNew(false);
      setNewConnection(false);
    }
  };

  const deleteConnection = async (conn) => {
    await axios.post(`${URL}/api/ceDeleteScenarioStateCondition/`, {
      scenarioStateConditionId: conn.id,
    });
    updateScenarioState({
      ...scenarioState,
      state_conditions: scenarioState.state_conditions.filter(
        (listedConnection) => listedConnection.id !== conn.id
      ),
    });
  };

  return (
    <>
      <Accordion
        className="staff-course-in-list"
        style={{ margin: "10px 0", background: "#fec195" }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <div className="staff-course-in-list-name">
            <Crop75 />
            SCENARIO STATE: {scenarioState.name}
            <div
              style={{
                marginInlineStart: "5px",
                display: "flex",
                alignItems: "center",
              }}
              title={scenarioState.is_active ? "Active" : "Not active"}
            >
              {scenarioState.is_active ? (
                <CheckCircle style={{ fontSize: "15px" }} />
              ) : (
                <Cancel style={{ fontSize: "15px" }} />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="staff-course-in-list-details">
            <div className="staff-course-in-list-edit-button-wrapper">
              <button
                className="prompt-generation-button"
                onClick={() => setEditPopupOpen(true)}
              >
                EDIT DETAILS FOR SCENARIO STATE "
                {(scenarioState?.name ?? "").toUpperCase()}"
              </button>
            </div>
          </div>
          <div className="staff-course-in-list-units">
            <div
              style={{
                margin: "15px 0 5px 0",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              CONNECTIONS TO CONDITIONS
            </div>
            {connectionsToConditions ? (
              connectionsToConditions.length ? (
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="list">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {(connectionsToConditions || []).map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id.toString()}
                            index={index}
                            isDragDisabled={connectionsToConditions.length < 2}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  backgroundColor: "#f797d2",
                                  height: "30px",
                                  display: "flex",
                                  alignItems: "center",
                                  fontWeight: "bold",
                                  padding: "5px 10px",
                                  margin: "5px 0",
                                }}
                                className="staff-course-in-list"
                              >
                                <div
                                  style={{
                                    flex: 1,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div className="order-marker">
                                    {item.order || index + 1}
                                  </div>
                                  {allScenarioConditions.find(
                                    (c) => c.id === item.target_condition_id
                                  )?.name || ""}
                                </div>
                                <button
                                  className="prompt-generation-button"
                                  style={{
                                    margin: 0,
                                    padding: "5px 10px",
                                    background: "#fa7d5f",
                                    border: "1px solid #282c34",
                                    color: "#282c34",
                                  }}
                                  onClick={() => deleteConnection(item)}
                                >
                                  DELETE CONNECTION
                                </button>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <i>No connections</i>
              )
            ) : (
              <>loading...</>
            )}
            {scenarioConditions.length ? (
              <div
                className="staff-course-in-list"
                style={{
                  margin: "10px 0",
                  background: "#f797d2",
                  display: "flex",
                  alignItems: "center",
                  height: "50px",
                  padding: "0 15px",
                }}
              >
                {editingNew ? (
                  <div
                    className="staff-course-in-list-name"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {/* image? */}
                    <div style={{ flex: 1 }}>
                      <select
                        className="rectangle-object"
                        placeholder="Select state for outcome..."
                        value={newConnection}
                        onChange={(e) =>
                          setNewConnection(Number(e.target.value))
                        }
                        disabled={savingNew}
                      >
                        <option value={0}>-- choose a condition --</option>
                        {scenarioConditions.map((condition) => (
                          <option key={condition.id} value={condition.id}>
                            {condition.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {savingNew ? (
                      <div
                        style={{
                          width: "80px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          className="loader prompt-generation-loader"
                          style={{ height: "35px" }}
                        >
                          <div id="bar1" className="bar"></div>
                          <div id="bar2" className="bar"></div>
                          <div id="bar3" className="bar"></div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <button
                          className="prompt-generation-button"
                          style={{
                            margin: "0 5px 0 0",
                            padding: "5px 10px",
                            background: !newConnection ? "#ccc" : "#0ae5a1",
                            border: "1px solid #282c34",
                            color: "#282c34",
                            cursor: !newConnection ? "not-allowed" : "pointer",
                          }}
                          disabled={!newConnection}
                          onClick={saveNewConnection}
                        >
                          SAVE
                        </button>
                        <button
                          className="prompt-generation-button"
                          style={{
                            margin: 0,
                            padding: "5px 10px",
                            background: "#fa7d5f",
                            border: "1px solid #282c34",
                            color: "#282c34",
                          }}
                          onClick={() => {
                            setEditingNew(false);
                            setNewConnection(0);
                          }}
                        >
                          CANCEL
                        </button>
                      </>
                    )}
                  </div>
                ) : (
                  <button
                    className="prompt-generation-button"
                    style={{
                      margin: 0,
                      padding: "5px 10px",
                    }}
                    onClick={() => setEditingNew(true)}
                  >
                    ADD A NEW CONNECTION TO A CONDITION
                  </button>
                )}
              </div>
            ) : (
              <div>
                <i>No available conditions, add conditions below</i>
              </div>
            )}
          </div>
          <div className="staff-course-in-list-edit-button-wrapper">
            <button
              className="prompt-generation-button"
              onClick={() => setDeleteScenarioStatePopup(true)}
              style={{
                backgroundColor: "#fa7d5f",
                color: "#282c34",
                fontWeight: "bold",
              }}
            >
              DELETE STATE "{(scenarioState?.name ?? "").toUpperCase()}"
            </button>
          </div>
        </AccordionDetails>
      </Accordion>
      <ScenarioStateDetailsPopup
        state={scenarioState}
        close={() => setEditPopupOpen(false)}
        save={(data) => saveScenarioState(data)}
        open={editPopupOpen}
        saving={saving}
      />
      <ConfirmDeletePopup
        open={deleteScenarioStatePopup}
        confirmDelete={deleteScenarioState}
        close={() => setDeleteScenarioStatePopup(false)}
      >
        Are you sure you want to delete scenario state "
        {scenarioState?.name || ""}"?
      </ConfirmDeletePopup>
      {ErrorPopupComponent}
    </>
  );
};

export default ScenarioStateInList;
