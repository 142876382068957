import { Delete } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  Radio,
  RadioGroup,
  styled,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import WarningPopup from "../../popups/admin/WarningPopup";

const USER_INFO_TYPE = {
  TEXT: "Text",
  DATE: "Date",
  ENUM: "Radio buttons",
  MULTI_PICK: "Multi-pick chips",
};

const UserInfoFieldDetailsPopup = ({
  open,
  close,
  field,
  isNew,
  save,
  saving,
}) => {
  const [fieldName, setFieldName] = useState("");
  const [type, setType] = useState("");
  const [question, setQuestion] = useState("");
  const [isRequired, setIsRequired] = useState(false);

  const [enumChoices, setEnumChoices] = useState([]);
  const [newEnumOption, setNewEnumOption] = useState("");

  const [multiPickConfigRange, setMultiPickConfigRange] = useState([1, 1]);
  const [multiPickConfigValues, setMultiPickConfigValues] = useState([]);
  const [newMultiPickConfigValue, setNewMultiPickConfigValue] = useState("");

  const [warningPopupOpen, setWarningPopupOpen] = useState(false);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
      fontFamily: "Assistant",
    },
  }));

  useEffect(() => {
    if (!isNew && field) {
      setFieldName(field.name);
      setQuestion(field.question);
      setType(field.type);
      setIsRequired(field.is_required);
      if (field.type === "ENUM") {
        let choices = JSON.parse(field.enum_choices ?? "[]");
        if (Array.isArray(choices)) setEnumChoices(choices);
      } else if (field.type === "MULTI_PICK") {
        let config = JSON.parse(field.multi_pick_config ?? "{}");
        if (
          config.range &&
          Array.isArray(config.range) &&
          config.range.length === 2
        ) {
          setMultiPickConfigRange([...config.range]);
        }
        if (config.values && Array.isArray(config.values)) {
          setMultiPickConfigValues([...config.values]);
        }
      }
    }
    return () => {
      setFieldName("");
      setQuestion("");
      setType("");
      setEnumChoices([]);
      setNewEnumOption("");
      setMultiPickConfigRange([1, 1]);
      setMultiPickConfigValues([]);
      setIsRequired(false);
    };
  }, [isNew, field]);

  const saveDetails = () => {
    save(
      {
        id: isNew ? null : field.id,
        name: fieldName,
        question: question,
        type: type,
        enum_choices: type === "ENUM" ? JSON.stringify(enumChoices) : null,
        multi_pick_config:
          type === "MULTI_PICK"
            ? JSON.stringify({
                range: multiPickConfigRange,
                values: multiPickConfigValues,
              })
            : null,
        is_required: isRequired,
      },
      isNew
    );
  };

  const handleEnumOption = () => {
    const trimmedValue = newEnumOption.trim();
    if (trimmedValue && !enumChoices.includes(trimmedValue)) {
      setEnumChoices((prev) => [...prev, trimmedValue]);
      setNewEnumOption("");
    }
  };

  const handleDeleteEnumOption = (valueToDelete) => {
    setEnumChoices((prev) => prev.filter((value) => value !== valueToDelete));
  };

  const handleMultiPickConfigValue = () => {
    const trimmedValue = newMultiPickConfigValue.trim();
    if (trimmedValue && !enumChoices.includes(trimmedValue)) {
      setMultiPickConfigValues((prev) => [...prev, trimmedValue]);
      setNewMultiPickConfigValue("");
    }
  };

  const handleDeleteMultiPickConfigValue = (valueToDelete) => {
    setMultiPickConfigValues((prev) =>
      prev.filter((value) => value !== valueToDelete)
    );
  };

  const cantSave = useMemo(() => {
    return (
      !fieldName?.trim().length ||
      !question?.trim().length ||
      !type?.length ||
      (type === "ENUM" && enumChoices.length < 2) ||
      (type === "MULTI_PICK" &&
        (multiPickConfigRange?.length !== 2 ||
          multiPickConfigValues.length < multiPickConfigRange[0]))
    );
  }, [
    fieldName,
    question,
    type,
    enumChoices,
    multiPickConfigRange,
    multiPickConfigValues,
  ]);

  return (
    <>
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        className="user-info-field-details"
      >
        <Box sx={{ ml: 5, mr: 5 }}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6">
            {isNew ? "NEW FIELD DETAILS" : `DETAILS FOR FIELD: ${field?.name}`}
          </Typography>
          <div className="prompt-generation-input">
            <label htmlFor="field-name">Field name</label>
            <textarea
              className="prompt-generation-textarea"
              style={{ height: "fit-content" }}
              name="field-name"
              placeholder="Enter field name..."
              value={fieldName}
              onChange={(e) => setFieldName(e.target.value)}
            />
            <br />
            <br />
          </div>
          <div className="prompt-generation-input">
            <label htmlFor="field-question">Question</label>
            <textarea
              className="prompt-generation-textarea"
              name="field-question"
              placeholder="Enter question..."
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
          </div>
          <br />
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isRequired}
                  onChange={() => setIsRequired((prev) => !prev)}
                  sx={{
                    "&.Mui-checked": {
                      color: "#0ae5a1",
                    },
                  }}
                />
              }
              label={
                <span style={{ display: "flex", alignItems: "center" }}>
                  Required&nbsp;&nbsp;
                  <HtmlTooltip
                    placement="right"
                    title={
                      <div>
                        For basic info only. If selected, users cannot use the
                        site without filling in this data.
                        <br />
                        <br />
                        <strong>WARNING:</strong> adding a new required field
                        will make all users fill in the data, including you
                      </div>
                    }
                  >
                    <img
                      style={{ height: "15px" }}
                      src="/images/information.png"
                      alt="info"
                    />
                  </HtmlTooltip>
                </span>
              }
              sx={{ m: 0 }}
            />
          </div>
          <br />
          <div>
            <label>Type</label>
            <RadioGroup
              value={type || ""}
              onChange={(e) => setType(e.target.value)}
              sx={{ display: "flex", flexDirection: "row" }}
            >
              {Object.keys(USER_INFO_TYPE).map((option) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={<Radio />}
                  label={USER_INFO_TYPE[option]}
                />
              ))}
            </RadioGroup>
          </div>
          {type === "ENUM" ? (
            <>
              <br />
              <div>
                <label>Enum options</label>
                <div
                  style={{ display: "flex" }}
                  className="prompt-generation-input"
                >
                  <input
                    placeholder="New Option"
                    name="new-enum-option"
                    value={newEnumOption}
                    onChange={(e) => setNewEnumOption(e.target.value)}
                    style={{
                      width: "300px",
                      marginRight: "5px",
                    }}
                  ></input>
                  <button onClick={handleEnumOption}>Add option</button>
                </div>

                <List>
                  {enumChoices.map((value) => (
                    <ListItem
                      sx={{ width: "fit-content" }}
                      key={value}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          onClick={() => handleDeleteEnumOption(value)}
                        >
                          <Delete />
                        </IconButton>
                      }
                    >
                      • {value}
                    </ListItem>
                  ))}
                </List>
              </div>
            </>
          ) : type === "MULTI_PICK" ? (
            <>
              <div>
                <div>
                  <label>Range (how many chips should the user pick)</label>
                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "5px" }}>
                      Between{" "}
                      <input
                        type="number"
                        onChange={(e) => {
                          setMultiPickConfigRange((prev) => [
                            Number(e.target.value),
                            Number(e.target.value) > prev[1]
                              ? Number(e.target.value)
                              : prev[1],
                          ]);
                        }}
                        value={multiPickConfigRange[0]}
                        min={1}
                        style={{ width: "50px" }}
                      ></input>
                    </div>
                    <div>
                      and{" "}
                      <input
                        type="number"
                        onChange={(e) =>
                          setMultiPickConfigRange((prev) => [
                            prev[0],
                            Number(e.target.value),
                          ])
                        }
                        min={multiPickConfigRange[0]}
                        value={multiPickConfigRange[1]}
                        style={{ width: "50px" }}
                      ></input>
                    </div>
                  </div>
                </div>
                <br />
                <div>
                  <label>Value chips</label>
                  <div
                    style={{ display: "flex" }}
                    className="prompt-generation-input"
                  >
                    <input
                      placeholder="New chip value"
                      name="new-enum-option"
                      value={newMultiPickConfigValue}
                      onChange={(e) =>
                        setNewMultiPickConfigValue(e.target.value)
                      }
                      style={{
                        width: "300px",
                        marginRight: "5px",
                      }}
                    ></input>
                    <button onClick={handleMultiPickConfigValue}>
                      Add chip value
                    </button>
                  </div>

                  <>
                    {multiPickConfigValues.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        onDelete={() => handleDeleteMultiPickConfigValue(value)}
                        sx={{ m: 1 }}
                      />
                    ))}
                  </>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <DialogActions sx={{ mt: 1, mb: 1, justifyContent: "flex-start" }}>
            <button
              className="prompt-generation-button"
              onClick={() => close()}
              style={{ marginLeft: 0 }}
            >
              CANCEL
            </button>
            <button
              className="prompt-generation-button"
              style={{ backgroundColor: "#0ae5a1" }}
              onClick={() => {
                if (!cantSave) {
                  if (isRequired) {
                    setWarningPopupOpen(true);
                  } else {
                    saveDetails();
                    close();
                  }
                }
              }}
              disabled={cantSave}
            >
              {saving ? "SAVING..." : "SAVE"}
            </button>
          </DialogActions>
        </Box>
      </Dialog>
      <WarningPopup
        open={warningPopupOpen}
        close={() => setWarningPopupOpen(false)}
        confirm={() => {
          saveDetails();
          close();
        }}
        confirmText={"SAVE ANYWAY"}
      >
        Are you sure you want to {isNew ? "save a new" : "make changes to a"}{" "}
        required field?
        <br />
        WARNING: This {isNew ? "will" : "might"} require all users (including
        you) to fill this in before using any part of the site
      </WarningPopup>
    </>
  );
};

export default UserInfoFieldDetailsPopup;
