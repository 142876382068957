import { Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart } from 'recharts';


const Graph = ({ graphData, lines, submit, showMessage }) => {
  return (
    <ResponsiveContainer width="85%" aspect="2.3">
      { submit ? (
        <LineChart data={graphData} margin={{ top: 5, right: 0, left: 0, bottom: 0 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis allowDecimals={false} />
          <Tooltip />
          <Legend />
          {lines}
        </LineChart>
        ) : ( <></> ) }
      { showMessage ? (<label className="message">No data to show</label>) : ( <></> ) }
    </ResponsiveContainer>
  );
};

export default Graph;
