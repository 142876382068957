import React from 'react';
//components
import PageHeader from '../components/header';
import SettingsTab from '../components/settingsTab';
import SettingsOpt from '../components/SettingsOpt';

import "../style/userSettings.css";
import { vw, vh } from '../tools/screen';

const UserSettings = () => {

    const settingsTabs = [
        { name: 'Account', img: '/images/settingsUser.png' },
        { name: 'Preferences', img: '/images/blueSettings.png' },
        { name: 'Payment plan', img: '/images/payment.png' },
        { name: 'Support', img: '/images/support.png' },
    ]
    return (
        <div style={{ width: vw(100), height: vh(100), overflow: 'hidden' }}>
            <PageHeader />
            <div className='userSettingsContainer'>
                <SettingsTab settingsTabs={settingsTabs} />
                <SettingsOpt settingsTabs={settingsTabs} />
            </div>
        </div >
    );
};

export default UserSettings;
