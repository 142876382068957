import axios from "axios";
import { URL } from "../tools/url";
import { useState, useEffect } from "react";
import useErrorPopup from "../tools/hooks/showError";
import AdminPage from "../components/admin/AdminPage";
import LLMTree from "../components/admin/llm-management/llm-tree"


const LLMManagement = () => {
  const [LLMs, setLLMs] = useState([])
  const [showError, ] = useErrorPopup();

  // get all the LLMs from the DB and insert them into the tree
  const fetchLLMs = async () => {
    try {
      const { data } = await axios.get(`${URL}/api/get_llm_models/`);
      setLLMs(data?.LLMs);
    } catch (err) {
      console.error(err);
      showError("Couldn't get LLMs");
    }
  };

  // set the models list
  useEffect(() => {
    fetchLLMs();
  }, []);


  return (
    <AdminPage currentLocation="LLM management">
      <div className="prompt-generation-container">
        <h1>LLM Management</h1>
          <LLMTree LLMs={LLMs} updateTree={fetchLLMs} />
      </div>
    </AdminPage>
  );
};

export default LLMManagement;