import { useEffect, useState } from "react";
import "../../../style/editCourse.css";
import useErrorPopup from "../../../tools/hooks/showError";
import axios from "axios";
import { URL } from "../../../tools/url";
import CourseInList from "./CourseInList";
import { School, SwapVert } from "@mui/icons-material";
import ReorderContentPopup from "./ReorderContentPopup";
import { IconButton } from "@mui/material";

const CourseList = () => {
  const [courses, setCourses] = useState(null);
  const [editingNew, setEditingNew] = useState(false);
  const [savingNew, setSavingNew] = useState(false);
  const [newCourseName, setNewCourseName] = useState("");
  const [reordering, setReordering] = useState(false);

  const [showError, ErrorPopupComponent] = useErrorPopup();

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const accessToken = localStorage.getItem("access_token");
        const decodedToken = JSON.parse(atob(accessToken.split(".")[1]));
        const { data } = await axios.get(
          `${URL}/api/ceGetEditableContent/?userId=${decodedToken.user_id}`
        );
        console.log("content from server", data?.courses ?? [])
        setCourses(data?.courses ?? []);
      } catch (err) {
        console.error(err);
        showError("Couldn't get course list.");
      }
    };
    fetchContent();
  }, []);

  const saveNewCourse = async () => {
    setSavingNew(true);
    try {
      const { data } = await axios.post(`${URL}/api/ceSaveCourse/`, {
        name: newCourseName,
        order: courses.length + 1,
      });
      setCourses((prev) => [...prev, data]);
    } catch (err) {
      console.error(err);
      showError("Couldn't save course");
    }
    setEditingNew(false);
    setNewCourseName("");
    setSavingNew(false);
  };

  return (
    <>
      <div className="staff-course-list">
        {courses ? (
          courses.length ? (
            <>
              {courses.map((course) => (
                <CourseInList
                  course={course}
                  key={course.id}
                  setCourses={setCourses}
                />
              ))}
              {false && courses.length >= 2 ? ( // remove button for now
                <IconButton
                  title="Reorder courses"
                  onClick={() => setReordering(true)}
                  style={{
                    width: "30px",
                    height: "30px",
                    color: "white",
                    background: "#262D3B",
                  }}
                >
                  <SwapVert />
                </IconButton>
              ) : (
                <></>
              )}
            </>
          ) : (
            <div className="staff-course-in-list">No courses available</div>
          )
        ) : (
          <div className="staff-course-in-list">
            <div className="loader prompt-generation-loader">
              <div id="bar1" className="bar"></div>
              <div id="bar2" className="bar"></div>
              <div id="bar3" className="bar"></div>
            </div>
          </div>
        )}
        <div
          className="staff-course-in-list"
          style={{
            margin: "10px 0",
            background: "#99a4ff",
            display: "flex",
            alignItems: "center",
            height: "50px",
            padding: "0 15px",
          }}
        >
          {editingNew ? (
            <div
              className="staff-course-in-list-name"
              style={{ display: "flex", alignItems: "center" }}
            >
              <School />
              <input
                placeholder="Enter course name..."
                value={newCourseName}
                onChange={(e) => setNewCourseName(e.target.value)}
                autoFocus
              ></input>
              {savingNew ? (
                <div
                  style={{
                    width: "200px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="loader prompt-generation-loader"
                    style={{ height: "35px" }}
                  >
                    <div id="bar1" className="bar"></div>
                    <div id="bar2" className="bar"></div>
                    <div id="bar3" className="bar"></div>
                  </div>
                </div>
              ) : (
                <>
                  <button
                    className="prompt-generation-button"
                    style={{
                      margin: "0 5px 0 0",
                      padding: "5px 10px",
                      background: false ? "#ccc" : "#0ae5a1",
                      border: "1px solid #282c34",
                      color: "#282c34",
                    }}
                    disabled={false}
                    onClick={saveNewCourse}
                  >
                    SAVE
                  </button>
                  <button
                    className="prompt-generation-button"
                    style={{
                      margin: 0,
                      padding: "5px 10px",
                      background: "#fa7d5f",
                      border: "1px solid #282c34",
                      color: "#282c34",
                    }}
                    onClick={() => {
                      setEditingNew(false);
                      setNewCourseName("");
                    }}
                  >
                    CANCEL
                  </button>
                </>
              )}
            </div>
          ) : (
            <button
              className="prompt-generation-button"
              style={{
                margin: 0,
                padding: "5px 10px",
              }}
              onClick={() => setEditingNew(true)}
            >
              ADD A NEW COURSE
            </button>
          )}
        </div>
      </div>
      <ReorderContentPopup
        open={reordering}
        close={() => setReordering(false)}
        contentArr={courses}
        type="course"
        //TODO save in server
        save={(arr) =>
          setCourses(
            arr.map((item, index) => {
              return {
                ...item,
                order: index + 1,
              };
            })
          )
        }
      />
      {ErrorPopupComponent}
    </>
  );
};

export default CourseList;
