//packages
import React, { useState } from 'react';
import axios from 'axios';
//components
import chat from '../stores/chat.store';
import PageHeader from '../components/header';
import GoogleFormSubmitPopup from '../components/popups/googleFormSubmit';
//style
import "../style/googleForm.css";
//tools
import { URL } from '../tools/url';
import config from "../config.json";
import { vw, vh } from '../tools/screen';

const GoogleFormPage = () => {

  //used to open popup to tell user that they submitted and bring them back to the page
  const [open, setOpen] = useState(false);

  //ask admin for config file holding codes
  const { api_key, spreadsheet_id } = config;

  //pulls data from google sheets to see past form submits
  const fetchData = async () => {
    try {
      const rawData = await axios.get(`https://sheets.googleapis.com/v4/spreadsheets/${spreadsheet_id}/values/Users!A1:F?key=${api_key}`);
      const data = rawData.data.values;
      return data;

    } catch (error) {
      console.error("Error retrieving data:", error);
    }
  };

  function compareEmails(email1, email2) {
    let str1;
    let str2;
    if (email1 && email2) {
      str1 = email1.trim().toLowerCase();
      str2 = email2.trim().toLowerCase();
    }
    else {
      str1 = email1;
      str2 = email2;
    }
    return str1 === str2;
  }

  //checks to see if user's email is in database
  function checkStringInArray(userEmail, array) {
    let emailIndex = array[0].indexOf("Email");
    let nameIndex = array[0].indexOf("First Name");
    for (let i = 0; i < array.length; i++) {
      if (compareEmails(array[i][emailIndex], userEmail)) {
        chat.setName(array[i][nameIndex]);
        return true;
      }
    }
    return false;
  }

  //checks to see if form is submitted by checking if user's email is in database. This is necessary as iframes on seperate 
  //domains are not able to be accessed to identify if specific buttons were clicked, or the internal src of iframe. This is how we detect if they submitted
  //the form already
  const checkIfSubmit = async () => {
    let data = await fetchData();
    if (checkStringInArray(chat.chatSettings.sessionId, data)) {
      setOpen(true);
      await axios.get(`${URL}/api/resetGoogleSheet/?reset=""`);
    }
  }

  return (
    <div style={{ width: vw(100), height: vh(100), overflow: 'hidden' }}>
      <PageHeader />
      {open ? <GoogleFormSubmitPopup /> : <></>}
      <div className="formContainer">
        <iframe
          title='Google form'
          src="https://docs.google.com/forms/d/e/1FAIpQLSdJNa5zn6JFblhy-CsjYejgBm6aqAbBalUrQXESnyN7mqdcJA/viewform?embedded=true"
          width="100%"
          height="100%"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          onLoad={checkIfSubmit}
        >
          Loading...
        </iframe>
      </div>
    </div>
  );
};

export default GoogleFormPage;
