import { Dialog, DialogActions, Typography } from "@mui/material";

const WarningPopup = ({ open, confirm, close, confirmText, children }) => {
  return (
    <Dialog open={open}>
      <Typography
        sx={{ mt: 4, mb: 2, ml: 5, mr: 5, textAlign: "center" }}
        variant="h6"
      >
        {children}
      </Typography>
      <DialogActions sx={{ m: 1, justifyContent: "center" }}>
        <button className="prompt-generation-button" onClick={close}>
          CANCEL
        </button>
        <button
          className="prompt-generation-button"
          style={{ backgroundColor: "#0ae5a1" }}
          onClick={() => {
            confirm();
            close();
          }}
        >
          {confirmText ?? "PROCEED"}
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default WarningPopup;
