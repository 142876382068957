//packages
import React, { useEffect } from "react";
//assets
import sound from "../openSound.mp3"
//style
import "../style/feedback.css";

const Feedback = ({ feedbackDisplay }) => {

  useEffect(() => {
    if (feedbackDisplay[0]) {
      new Audio(sound).play();
    }
  }, [feedbackDisplay])

  if (feedbackDisplay[0]?.type === "cue") {
    return (
      <div className={`emotionalAssistant ${feedbackDisplay ? '' : 'fade'}`}>
        <div className="feedbackSymbol">
          <h2>{feedbackDisplay[0]?.title}</h2>
          {feedbackDisplay[0]?.display ? <img
            src={feedbackDisplay[0]?.display}
            alt="Cue" /> : <></>}
        </div>
      </div>

    );
  } else if (feedbackDisplay[0]?.type === "posFeedback") {
    return (
      <div className={`emotionalAssistant ${feedbackDisplay ? '' : 'fade'}`}>
        {feedbackDisplay.map((feedback) => (

          <div className="posFeedback" key={feedback}>
            <img
              src="/images/positiveFeedback.png"
              alt="star" />
            <h2>{feedback.display}</h2>
          </div>

        ))}
      </div>
    );
  }
};

export default Feedback;
