import AdminPage from "../components/admin/AdminPage";
import ConversationHistoryTable from "../components/admin/conversation-history/ConversationHistoryTable";

const ConversationHistory = () => {
  return (
    <AdminPage currentLocation="Conversation history">
      <div className="prompt-generation-container">
        <h1>Conversation history</h1>
        <div className="prompt-generation-section no-border-bottom" style={{ width: "1000px" }}>
          <ConversationHistoryTable />
        </div>
      </div>
    </AdminPage>
  );
};

export default ConversationHistory;
