import { useState, useEffect, useMemo } from "react";
import WarningPopup from "../../popups/admin/WarningPopup";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Box, Dialog, DialogActions, Typography } from "@mui/material";

const ReorderContentPopup = ({ open, contentArr, save, close, type = "" }) => {
  const [warningPopupOpen, setWarningPopupOpen] = useState(false);
  const [arr, setArr] = useState([]);

  useEffect(() => setArr(contentArr), [contentArr]);

  const closeAndReset = () => {
    close();
    setArr(contentArr);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(arr);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setArr(items);
  };

  const hasUnsavedChanges = useMemo(
    () => JSON.stringify(arr) !== JSON.stringify(contentArr),
    [arr, contentArr]
  );

  return (
    <>
      <Dialog open={open} maxWidth="md" fullWidth>
        <Box sx={{ ml: 5, mr: 5 }}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6">
            REORDER{" "}
            {type === "course" ? "COURSES" : type === "unit" ? "UNITS" : "CONTENT"}
          </Typography>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="list">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {arr.map((item, index) => (
                    <Draggable
                      key={item.type ? `${item.type}${item.id}` : item.id}
                      draggableId={(item.type ? `${item.type}${item.id}` : item.id).toString()}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            backgroundColor:
                              type === "course"
                                ? "#99a4ff"
                                : type === "unit"
                                ? "#ffcefb"
                                : item.type === "info-page"
                                ? "#f9f871"
                                : "#ffeeca",
                            height: "30px",
                            display: "flex",
                            alignItems: "center",
                            fontWeight: "bold",
                            padding: "5px 10px",
                            margin: "5px 0"
                          }}
                          className="staff-course-in-list"
                        >
                          {item.name}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <DialogActions sx={{ mt: 1, mb: 1, justifyContent: "flex-start" }}>
            <button
              className="prompt-generation-button"
              onClick={() => {
                if (hasUnsavedChanges) {
                  setWarningPopupOpen(true);
                } else close();
              }}
              style={{ marginLeft: 0 }}
            >
              CANCEL
            </button>
            <button
              className="prompt-generation-button"
              style={{ backgroundColor: "#0ae5a1" }}
              onClick={() => {
                save(arr);
                close();
              }}
            >
              SAVE
            </button>
          </DialogActions>
        </Box>
      </Dialog>
      <WarningPopup
        open={warningPopupOpen}
        confirm={() => closeAndReset()}
        close={() => setWarningPopupOpen(false)}
        confirmText="CLOSE"
      >
        Are you sure you want to close the reorder window?
        <br />
        WARNING: this will discard your changes
      </WarningPopup>
    </>
  );
};

export default ReorderContentPopup;
