import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import WarningPopup from "../../../popups/admin/WarningPopup";

const STATE_TYPES = ["START", "END", "INNER"];

const STATE_END_TYPES = ["SUCCESS", "FAILURE"];

const ScenarioStateDetailsPopup = ({ state, open, close, save, saving }) => {
  const [name, setName] = useState("");
  const [active, setActive] = useState(false);
  const [stateType, setStateType] = useState("");
  const [endingType, setEndingType] = useState("");
  const [statePrompt, setStatePrompt] = useState("");

  const [warningPopupOpen, setWarningPopupOpen] = useState(false);

  useEffect(() => {
    setName(state?.name ?? "");
    setActive(state?.is_active ?? false);
    setStateType(state?.type ?? "");
    setEndingType(state?.ending_type ?? "");
    setStatePrompt(state?.prompt ?? "");
  }, [state]);

  const hasUnsavedChanges = useMemo(() => {
    return (
      name !== state.name ||
      active !== state.is_active ||
      stateType !== state.type ||
      endingType !== state.endingType ||
      statePrompt !== state.prompt
    );
  }, [name, active, stateType, endingType, statePrompt, state]);

  const closeAndReset = () => {
    setName(state?.name ?? "");
    setActive(state?.is_active ?? false);
    setStateType(state?.type ?? "");
    setEndingType(state?.ending_type ?? "");
    setStatePrompt(state?.prompt ?? "");
    close();
  };

  return (
    <>
      <Dialog open={open} maxWidth="md" fullWidth>
        <Box sx={{ ml: 5, mr: 5 }}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6">
            DETAILS FOR STATE: {state.name}
          </Typography>
          <div className="prompt-generation-input">
            <label htmlFor="state-name">Scenario state name</label>
            <textarea
              className="prompt-generation-textarea"
              style={{ height: "fit-content" }}
              name="state-name"
              placeholder="Enter scenario state name..."
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={saving}
            />
            <br />
          </div>
          <br />
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={active}
                  onChange={() => setActive((prev) => !prev)}
                  sx={{
                    "&.Mui-checked": {
                      color: "#0ae5a1",
                    },
                  }}
                />
              }
              label="Active"
              labelPlacement="start"
              sx={{ m: 0, fontFamily: "Assistant", fontSize: "1rem" }}
              disabled={saving}
            />
          </div>
          <br />
          <div>
            <label htmlFor="state-type">State type</label>
            <select
              className="rectangle-object"
              name="state-type"
              placeholder="Select state type..."
              value={stateType}
              onChange={(e) => setStateType(e.target.value)}
              disabled={saving}
            >
              <option value={""}>-- choose a state type --</option>
              {STATE_TYPES.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          <br />
          <div>
            <label htmlFor="ending-type">Ending type</label>
            <select
              className="rectangle-object"
              name="ending-type"
              placeholder="Select ending type..."
              value={endingType}
              onChange={(e) => setEndingType(e.target.value)}
              disabled={saving}
            >
              <option value={""}>-- choose an ending type --</option>
              {STATE_END_TYPES.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          <br />
          <div className="prompt-generation-input">
            <label htmlFor="prompt">Prompt</label>
            <textarea
              disabled={saving}
              className="prompt-generation-textarea"
              name="prompt"
              placeholder="Enter state prompt..."
              value={statePrompt}
              onChange={(e) => setStatePrompt(e.target.value)}
            />
          </div>
          <DialogActions sx={{ mt: 1, mb: 1, justifyContent: "flex-start" }}>
            <button
              className="prompt-generation-button"
              onClick={() => {
                if (hasUnsavedChanges) {
                  setWarningPopupOpen(true);
                } else close();
              }}
              style={{ marginLeft: 0 }}
            >
              CANCEL
            </button>
            <button
              className="prompt-generation-button"
              style={{ backgroundColor: saving ? "#ccc" : "#0ae5a1" }}
              onClick={() => {
                save({
                  ...state,
                  name: name,
                  is_active: active,
                  type: stateType ? stateType : null,
                  ending_type: endingType ? endingType : null,
                });
                close();
              }}
              disabled={saving}
            >
              {saving ? "SAVING..." : "SAVE"}
            </button>
          </DialogActions>
        </Box>
      </Dialog>
      <WarningPopup
        open={warningPopupOpen}
        confirm={() => closeAndReset()}
        close={() => setWarningPopupOpen(false)}
        confirmText="CLOSE"
      >
        Are you sure you want to close the editing window?
        <br />
        WARNING: this will discard your changes
      </WarningPopup>
    </>
  );
};

export default ScenarioStateDetailsPopup;
