import { useEffect, useMemo, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "../style/newUserForm.css"; // CSS for styling
import UserInfoForm from "../components/user-info/UserInfoForm";
import PageHeader from "../components/header";
import { Skeleton } from "@mui/material";
import axios from "axios";
import { URL } from "../tools/url";
import { useNavigate } from "react-router-dom";

const NewUserForm = () => {
  const [questions, setQuestions] = useState([]);
  const [formData, setFormData] = useState(null);

  const [userId, setUserId] = useState(null);

  const [answering, setAnswering] = useState(false);
  const [loadingQuestions, setLoadingQuestions] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    const decodedToken = JSON.parse(atob(accessToken.split(".")[1]));
    setUserId(decodedToken.user_id);
  }, []);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const { data } = await axios.get(
          `${URL}/api/usinfGetFields/?userId=${userId}&required=true`
        );
        // const { data } = await axios.get(`${URL}/api/usinfGetNewUserFormFields/?userId=${userId}`);
        setQuestions(data.fields);
        console.log(data);
      } catch (err) {
        console.error(err);
        alert("Couldn't get questions");
      }
    };
    if (userId) {
      fetchQuestions();
      setLoadingQuestions(false);
    }
  }, [userId]);

  useEffect(() => {
    if (questions?.length) {
      let fd = {};
      for (let i = 0; i < questions.length; i++) {
        fd[questions[i].name] = {
          id: questions[i].id,
          value: questions[i].answer ?? "",
        };
      }
      console.log("formData", fd);
      setFormData({ ...fd });
    }
  }, [questions]);

  const validateMultiPick = (obj) => {
    try {
      const question = questions.find((q) => q.id === obj.id);
      if (question.type !== "MULTI_PICK") return true;
      const range = JSON.parse(
        question.multi_pick_config ?? '{"range": [0, 0]}'
      ).range;
      const values = JSON.parse(obj.value);
      if (!Array.isArray(values))
        throw Error("Values of multi-pick must be an array");
      if (values.length < range[0] || values.length > range[1]) return false;
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const canAnswer = useMemo(() => {
    return (
      formData &&
      questions &&
      Object.values(formData).filter(
        (item) => item.value?.length && validateMultiPick(item)
      ).length === questions.length
    );
  }, [formData, questions]);

  const answerQuestions = async () => {
    setAnswering(true);
    try {
      const dataArr = Object.values(formData).map((item) => {
        return {
          userId: userId,
          fieldId: item.id,
          answer: item.value,
        };
      });
      console.log(dataArr);
      await axios.post(`${URL}/api/usinfSaveAnswers/`, dataArr);
      navigate('/');
    } catch (err) {
      console.error(err);
      alert("Couldn't save answers");
    }
    setAnswering(false);
  };

  return (
    <>
      <PageHeader />
      {
        <div className="new-user-form-page">
          <h1>Help us get to know you</h1>
          <div className="new-user-form-wrapper">
            {loadingQuestions ? (
              <div>
                <Skeleton width={"40%"} height={"40px"} />
                <Skeleton height={"80px"} />
                <br />
                <Skeleton width={"40%"} height={"40px"} />
                <Skeleton height={"80px"} />
                <br />
                <Skeleton width={"40%"} height={"40px"} />
                <Skeleton height={"80px"} />
                <br />
                <Skeleton width={"40%"} height={"40px"} />
                <Skeleton height={"80px"} />
                <br />
              </div>
            ) : (
              <>
                <UserInfoForm
                  questions={questions}
                  formData={formData}
                  setFormData={setFormData}
                />
                <br />
                <button
                  onClick={answerQuestions}
                  className="answer-userinfo-questions-button"
                  disabled={!canAnswer || answering}
                >
                  {answering ? "Answering..." : "Answer questions"}
                </button>
              </>
            )}
          </div>
        </div>
      }
    </>
  );
};

export default NewUserForm;
