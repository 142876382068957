import axios from "axios";
import { URL } from "../../tools/url";
import { useEffect, useMemo, useState } from "react";
import UserInfoForm from "./UserInfoForm";

const ScenarioUserInfo = ({
  fields,
  userId,
  fetchQuestions,
  canStartConversation,
}) => {
  const [formData, setFormData] = useState({});

  const [answering, setAnswering] = useState(false);

  useEffect(() => {
    if (fields?.length) {
      let fd = {};
      for (let i = 0; i < fields.length; i++) {
        fd[fields[i].name] = {
          id: fields[i].id,
          value: fields[i].answer ?? "",
        };
      }
      console.log("formData", fd);
      setFormData({ ...fd });
    }
  }, [fields]);

  const answerQuestions = async () => {
    setAnswering(true);
    try {
      const dataArr = Object.values(formData).map((item) => {
        return {
          userId: userId,
          fieldId: item.id,
          answer: item.value,
        };
      });
      console.log(dataArr);
      await axios.post(`${URL}/api/usinfSaveAnswers/`, dataArr);
      fetchQuestions();
    } catch (err) {
      console.error(err);
      alert("Couldn't save answers");
    }
    setAnswering(false);
  };

  const validateMultiPick = (obj) => {
    try {
      const question = fields.find((q) => q.id === obj.id);
      if (question.type !== "MULTI_PICK") return true;
      const range = JSON.parse(
        question.multi_pick_config ?? '{"range": [0, 0]}'
      ).range;
      const values = JSON.parse(obj.value);
      if (!Array.isArray(values))
        throw Error("Values of multi-pick must be an array");
      if (values.length < range[0] || values.length > range[1]) return false;
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const canAnswer = useMemo(() => {
    return (
      formData &&
      fields &&
      Object.values(formData).filter(
        (item) => item.value?.length && validateMultiPick(item)
      ).length === fields.length
    );
  }, [formData, fields]);

  return (
    <div className="scenario-user-info-form">
      <h3 style={{ color: canStartConversation ? "inherit" : "red" }}>
        <strong>
          {canStartConversation
            ? "Your info"
            : "Before we start, please fill in this info:"}
        </strong>
      </h3>
      <br />
      <UserInfoForm
        questions={fields}
        formData={formData}
        setFormData={setFormData}
      />
      <br />
      <button
        onClick={answerQuestions}
        className="answer-userinfo-questions-button"
        disabled={answering || !canAnswer}
      >
        {answering ? "Answering..." : "Answer questions"}
      </button>
    </div>
  );
};

export default ScenarioUserInfo;
