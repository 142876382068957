import { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Collapse,
  Dialog,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Delete,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import axios from "axios";
import { URL } from "../../../tools/url";
import ConfirmDeletePopup from "../../popups/admin/ConfirmDeletePopup";
import { decodeJSONFromServer } from "../../../tools/admin_enums";
import useErrorPopup from "../../../tools/hooks/showError";
import GeneratePrompts from "./GeneratePrompts";
import AddPrompt from "./AddPrompt";

const MIN_TEXTAREA_HEIGHT = 32;

function Row(props) {
  const { row, selected, onClick, deleteRow, editRow } = props;
  const [markdownOpen, setMarkdownOpen] = useState(false);
  const [promptOpen, setPromptOpen] = useState(false);
  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);
  const [editPrompt, setEditPrompt] = useState(false);
  const [prompt, setPrompt] = useState("");
  const textareaRef = useRef();

  useEffect(() => {
    setPrompt(row?.prompt_str || "");
  }, [row?.prompt_str]);

  const toggleEditPrompt = () => {
    if (editPrompt) {
      // cancel
      setPrompt(row.prompt_str);
      setEditPrompt(false);
    } else {
      // edit
      setEditPrompt(true);
    }
  };

  const saveChangesToPrompt = () => {
    editRow({
      ...row,
      prompt_str: prompt,
      test_results: null,
      avg_response_time_sec: null,
      percent_correct: null,
      percent_false_positives: null,
      percent_false_negatives: null,
      percent_exceptions: null,
      is_active: false,
    });
    setEditPrompt(false);
    setPromptOpen(false);
  };

  useEffect(() => {
    // convoluted, but it's necessary for the textarea's size
    if (promptOpen) {
      setTimeout(() => {
        if (textareaRef.current) {
          // Reset height - important to shrink on delete
          textareaRef.current.style.height = "inherit";
          // Set height
          textareaRef.current.style.height = `${Math.max(
            textareaRef.current.scrollHeight,
            MIN_TEXTAREA_HEIGHT
          )}px`;
        }
      }, 50);
    }
  }, [promptOpen]);

  return (
    <>
      <TableRow
        onClick={onClick}
        sx={{
          "& > *": { borderBottom: "unset" },
          bgcolor: selected ? "#0ae5a170" : "unset",
          transform: "1s",
          "&:hover": {
            bgcolor: selected ? "#0ae5a170" : "unset",
          },
          cursor: selected ? "default" : "pointer",
          "& .MuiTableCell-root": { fontWeight: selected ? "bold" : "inherit" },
        }}
      >
        <TableCell>
          {row.test_results ? (
            <IconButton
              aria-label="expand row"
              size="small"
              className="prompt-generation-expand-table-row"
              title={markdownOpen ? "Collapse" : "Show detailed results"}
              onClick={(e) => {
                e.stopPropagation();
                setMarkdownOpen(!markdownOpen);
              }}
            >
              {markdownOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          ) : (
            <></>
          )}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          title={"Click here to see full prompt"}
          onClick={(e) => {
            e.stopPropagation();
            setPromptOpen(true);
          }}
          sx={{ cursor: "pointer" }}
        >
          {row.prompt_str?.substring(0, 10)}...
        </TableCell>
        <TableCell>{row.llm_model?.name || ""}</TableCell>
        <TableCell>
          {typeof row.llm_model?.parent_model_family?.input_cost_per_million_tokens === "number"
            ? row.llm_model?.parent_model_family?.input_cost_per_million_tokens.toFixed(3)
            : null}
        </TableCell>
        <TableCell>
          {typeof row.avg_response_time_sec === "number"
            ? row.avg_response_time_sec.toFixed(3)
            : null}
        </TableCell>
        <TableCell>
          {typeof row.percent_correct === "number"
            ? row.percent_correct.toFixed(1)
            : null}
        </TableCell>
        <TableCell>
          {typeof row.percent_false_positives === "number"
            ? row.percent_false_positives.toFixed(1)
            : null}
        </TableCell>
        <TableCell>
          {typeof row.percent_false_negatives === "number"
            ? row.percent_false_negatives.toFixed(1)
            : null}
        </TableCell>
        <TableCell>
          {typeof row.percent_exceptions === "number"
            ? row.percent_exceptions.toFixed(1)
            : null}
        </TableCell>
        <TableCell>
          <IconButton
            title={"Delete row"}
            onClick={(e) => {
              e.stopPropagation();
              setConfirmDeletePopup(true);
            }}
          >
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow
        sx={{
          bgcolor: selected ? "#0ae5a170" : "unset",
        }}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse
            in={markdownOpen && row.test_results}
            timeout="auto"
            unmountOnExit
          >
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Test results
              </Typography>
              <pre className="prompt-generation-result-markdown">
                {row.test_results}
              </pre>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog open={promptOpen} onClose={() => setPromptOpen(false)}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "15px 35px 5px 35px",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5">Full prompt content</Typography>
          <button
            className="prompt-generation-button"
            onClick={toggleEditPrompt}
            style={editPrompt ? { backgroundColor: "#fa7d5f" } : {}}
          >
            {editPrompt ? "DISCARD CHANGES" : "EDIT PROMPT"}
          </button>
        </div>
        <textarea
          ref={textareaRef}
          disabled={!editPrompt}
          className="MuiTypography-root MuiTypography-body1 css-4lnsu6-MuiTypography-root"
          style={{
            margin: "2 5 5 5",
            whiteSpace: "pre-line",
            minHeight: MIN_TEXTAREA_HEIGHT,
            resize: "none",
            outline: "none",
            border: "none",
            width: "500px",
            marginBottom: "10px",
            padding: "20px",
            fontSize: "15px",
          }}
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
        />
        <div
          style={{
            opacity: prompt !== row.prompt_str ? 1 : 0,
            transition: "0.25s",
            margin: "0px 0px 20px 20px",
          }}
        >
          <button
            className="prompt-generation-button"
            onClick={saveChangesToPrompt}
            disabled={prompt === row.prompt_str}
          >
            SAVE CHANGES
          </button>
          {row.test_results ? (
            <div>WARNING: saving will nullify test results</div>
          ) : (
            <></>
          )}
        </div>
      </Dialog>
      <ConfirmDeletePopup
        open={confirmDeletePopup}
        close={() => setConfirmDeletePopup(false)}
        confirmDelete={deleteRow}
      >
        Are you sure you want to delete this row?
      </ConfirmDeletePopup>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number,
    prompt_str: PropTypes.string,
    llm_name: PropTypes.string,
    input_cost_per_million: PropTypes.number,
    avg_response_time_sec: PropTypes.number,
    percent_correct: PropTypes.number,
    percent_false_positives: PropTypes.number,
    percent_false_negatives: PropTypes.number,
    percent_exceptions: PropTypes.number,
    test_results: PropTypes.string,
  }).isRequired,
};

const MAX_PROMPTS_LENGTH = 15;

const PromptTable = ({
  builder,
  setBuilder,
  save,
  loading,
  callingServer,
  setCallingServer,
  unsavedDefinitionChanges,
  unsavedTestChanges,
  unsavedPromptChanges,
  setUnsavedPromptChanges,
}) => {
  const [rows, setRows] = useState([]);

  const [saving, setSaving] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [executing, setExecuting] = useState(false);

  const [generatePopupOpen, setGeneratePopupOpen] = useState(false);
  const [addPopupOpen, setAddPopupOpen] = useState(false);

  const [showError, ErrorPopupComponent] = useErrorPopup();

  useEffect(() => {
    setRows(
      builder?.question?.prompts?.length ? builder?.question?.prompts : []
    );
  }, [builder]);

  useEffect(() => {
    if (
      JSON.stringify([...rows]) !==
      JSON.stringify([...(builder?.question?.prompts || [])])
    )
      setUnsavedPromptChanges(true);
    else setUnsavedPromptChanges(false);
  }, [rows, builder]);

  const savePrompts = async (promptsToSave) => {
    setSaving(true);
    save({
      param_builder: {
        ...builder,
        question: {
          ...builder.question,
          prompts: promptsToSave,
        },
      },
      definition_changed: false,
      tests_changed: false,
      prompts_changed: true,
    });
    setSaving(false);
  };

  const selectPrompt = (prompt) => {
    setRows((prev) =>
      prev.map((item) => {
        return {
          ...item,
          is_active: item.id === prompt.id,
        };
      })
    );
  };

  const executeTests = async () => {
    setExecuting(true);
    setCallingServer(true);
    try {
      const { data } = await axios.post(
        `${URL}/api/classification_builder_execute_tests/`,
        {
          param_builder: {
            ...builder,
            question: {
              ...builder.question,
              prompts: rows,
            },
          },
        }
      );
      if (data) setBuilder(decodeJSONFromServer(data));
    } catch (err) {
      console.error(err);
      showError("Cannot execute tests");
    }
    setExecuting(false);
    setCallingServer(false);
  };

  const cantAddOrGenerate = useMemo(() => {
    return saving
      ? "busy saving"
      : generating
      ? "busy generating"
      : executing
      ? "busy executing tests"
      : callingServer
      ? "busy calling server"
      : unsavedDefinitionChanges
      ? "you have unsaved changes in name or definition"
      : unsavedTestChanges
      ? "you have unsaved changes in your test set"
      : !builder?.question?.definition
      ? "you have no saved definition"
      : rows?.length >= MAX_PROMPTS_LENGTH
      ? "you have reached the maximum number of prompts"
      : "";
  }, [
    builder,
    saving,
    generating,
    executing,
    callingServer,
    unsavedDefinitionChanges,
    unsavedTestChanges,
    rows,
    MAX_PROMPTS_LENGTH,
  ]);

  const cantExecute = useMemo(() => {
    return saving
      ? "busy saving"
      : generating
      ? "busy generating"
      : executing
      ? "busy executing tests"
      : !rows?.length
      ? "no prompts to execute tests on, generate prompts first"
      : callingServer
      ? "busy calling server"
      : !builder?.question?.definition
      ? "you have no saved definition"
      : unsavedDefinitionChanges
      ? "you have unsaved changes in name or definition"
      : unsavedTestChanges
      ? "You have unsaved changes in your test set"
      : unsavedPromptChanges
      ? "You have unsaved changes in your prompts - save them first"
      : "";
  }, [
    saving,
    generating,
    executing,
    rows,
    callingServer,
    unsavedDefinitionChanges,
    unsavedTestChanges,
    unsavedPromptChanges,
    builder,
  ]);

  const cantSave = useMemo(() => {
    // const isSelected = rows?.filter((item) => item.is_active)?.length === 1;
    return !rows?.length
      ? "table is empty"
      : saving
      ? "busy saving"
      : generating
      ? "busy generating"
      : executing
      ? "busy executing"
      : callingServer
      ? "busy calling server"
      : !builder?.question?.definition
      ? "you have no saved definition"
      : unsavedDefinitionChanges
      ? "you have unsaved changes in name or definition"
      : unsavedTestChanges
      ? "you have unsaved changes in your test set"
      : "";
  }, [
    saving,
    generating,
    executing,
    rows,
    callingServer,
    unsavedDefinitionChanges,
    unsavedTestChanges,
  ]);

  useEffect(() => {
    if (generating) setCallingServer(true);
    else setCallingServer(false);
  }, [generating]);

  return (
    <div className="prompt-generation-section">
      <h2 style={{ marginBottom: "10px" }}>Prompts and results</h2>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Prompt</TableCell>
              <TableCell>LLM</TableCell>
              <TableCell>Input cost</TableCell>
              <TableCell>Average response time&nbsp;(s)</TableCell>
              <TableCell>Correct</TableCell>
              <TableCell>False positives</TableCell>
              <TableCell>False negatives</TableCell>
              <TableCell>Exceptions</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <Row
                key={row.id}
                row={row}
                onClick={() => selectPrompt(row)}
                selected={row.is_active}
                deleteRow={() =>
                  savePrompts([...rows.filter((item) => item.id !== row.id)])
                }
                editRow={(data) => {
                  let newArr = [...rows];
                  newArr[newArr.findIndex((item) => item.id === row.id)] = data;
                  savePrompts(newArr);
                }}
              />
            ))}
            {generating || loading ? (
              <TableRow>
                <TableCell colSpan={12}>
                  <div
                    className="loader prompt-generation-loader"
                    style={{ height: "70px" }}
                  >
                    <div id="bar1" className="bar"></div>
                    <div id="bar2" className="bar"></div>
                    <div id="bar3" className="bar"></div>
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: "flex", marginTop: "8px" }}>
        <button
          className="prompt-generation-button"
          disabled={!!cantAddOrGenerate}
          title={
            cantAddOrGenerate ? `Can't generate: ${cantAddOrGenerate}` : ""
          }
          onClick={() => setGeneratePopupOpen(true)}
        >
          {generating ? "GENERATING..." : "GENERATE PROMPTS (SERVER)"}
        </button>
        <button
          className="prompt-generation-button"
          disabled={!!cantAddOrGenerate}
          title={
            cantAddOrGenerate ? `Can't add a prompt: ${cantAddOrGenerate}` : ""
          }
          onClick={() => setAddPopupOpen(true)}
        >
          ADD PROMPT (MANUALLY)
        </button>
        <button
          className="prompt-generation-button"
          disabled={!!cantExecute}
          title={cantExecute ? `Can't execute tests: ${cantExecute}` : ""}
          onClick={executeTests}
        >
          {executing ? "EXECUTING..." : "EXECUTE TESTS"}
        </button>
        <button
          className="prompt-generation-button"
          disabled={!!cantSave}
          title={cantSave ? `Can't save: ${cantSave}` : ""}
          onClick={() => savePrompts(rows)}
        >
          {saving ? "SAVING..." : "SAVE PROMPTS"}
        </button>
      </div>
      <Dialog
        open={generating || generatePopupOpen}
        onClose={() => {
          if (!generating) setGeneratePopupOpen(false);
        }}
      >
        <Typography
          sx={{
            mt: 4,
            mb: 2,
            ml: 5,
            mr: 5,
            textAlign: "center",
            width: "500px",
          }}
          variant="h6"
        >
          Generate prompts
        </Typography>
        <GeneratePrompts
          prompts={rows}
          MAX_PROMPTS_LENGTH={MAX_PROMPTS_LENGTH}
          complete={(data) => {
            setBuilder(data);
            setGeneratePopupOpen(false);
          }}
          disabled={!!cantAddOrGenerate}
          builder={builder}
          generating={generating}
          setGenerating={setGenerating}
        />
      </Dialog>
      <Dialog
        open={addPopupOpen}
        onClose={() => {
          setAddPopupOpen(false);
        }}
      >
        <Typography
          sx={{
            mt: 4,
            mb: 2,
            ml: 5,
            mr: 5,
            textAlign: "center",
            width: "500px",
          }}
          variant="h6"
        >
          Add a prompt
        </Typography>
        <AddPrompt
          complete={(data) => {
            savePrompts(data);
            setAddPopupOpen(false);
          }}
          disabled={!!cantAddOrGenerate}
          builder={builder}
        />
      </Dialog>
      {ErrorPopupComponent}
    </div>
  );
};

export default PromptTable;
