import AdminPage from "../components/admin/AdminPage";
import UserInfoFieldList from "../components/admin/user-info/UserInfoFieldList";

const AdminUserInfoQuestions = () => {
  return (
    <AdminPage currentLocation="User info questions">
      <div className="prompt-generation-container">
        <div
          className={`prompt-generation-section`}
          style={{ borderBottom: "none" }}
        >
          <h1>User info questions</h1>
          <br />
          <UserInfoFieldList type="table" />
        </div>
      </div>
    </AdminPage>
  );
};

export default AdminUserInfoQuestions;