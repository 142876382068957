//packages
import React from "react";
//style
import "../style/coachChatHistory.css";


const CoachChatHistory = (props) => {
   
    return (
        <div className="coachConvContainer">
        </div>
    );
};

export default CoachChatHistory;
