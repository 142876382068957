//packages
import React from "react";
import { useNavigate } from "react-router-dom";
//style
import '../../style/chatLoader.css';
//tools
import { vh } from "../../tools/screen";
//stores
import chat from "../../stores/chat.store";


const ChatLoader = ({ setLoadDisplay, loading, setLoading, setAvatar }) => {
    const navigate = useNavigate();

    const goBack = async () => {
        if (localStorage.getItem("courseId")) navigate(`/course/${localStorage.getItem("courseId")}`);
        else navigate("/");
    }

    const colors = ['#D5E2E0', '#F8EAEC', '#CAE0E4', '#F7DDD9', '#F7E6DA', '#EEF3E9', '#C8C7D6', '#F9E0E2', '#E3E9DD', '#C4DBD9', '#E4E4EC', '#F0D7DF', '#FFF3EB']

    return (
        <div className="loaderContainer">
            <div className="centerContent">
                <div className="backLoad" onClick={() => { goBack() }}>
                    {loading ?
                        <>
                            <img src="/images/whiteBack.png" alt="back" style={{ height: vh(2) }} />
                            <h2>Home</h2>
                        </>
                        :
                        <>
                            <img src="/images/back.png" alt="back" style={{ height: vh(2) }} />
                            <h2 style={{ color: '#262d3b' }}>Home</h2>
                        </>}

                </div>

                {loading ?
                    <div className="topLoader" >

                        <div className='loadComponent' style={{ marginTop: vh(3), alignSelf: 'center' }} >
                            <h2 className="loadTitle">Loading conversation</h2>
                            <div className="loadingio-spinner-ellipsis-3luurm4d5ky"><div className="ldio-wbsk1rjduxb">
                                <div></div><div></div><div></div><div></div><div></div>
                            </div></div>
                        </div>
                        <h2
                            className="loadTitle"
                            style={{ marginTop: vh(8.5), fontSize: vh(2.75), fontWeight: 'normal' }}> This can take a minute or two. Please read the info below and prepare to start:</h2>
                    </div>
                    :
                    <div className="topLoader" style={{
                        backgroundColor: '#0ae5a0', cursor: "pointer"
                    }} onClick={() => { setLoadDisplay(false) }}>

                        <div className='loadComponent' style={{ marginTop: vh(5), alignSelf: 'center', }} >
                            <h2 className="loadTitle" style={{ color: '#262d3b', fontWeight: 'bolder' }}>Ready, tap to start conversation</h2>

                        </div>
                    </div>
                }
                <div className="homeInfoDiv" style={{ marginTop: vh(10) }}>
                    <h2 className="homeConvTitle" style={{ fontWeight: 'bold' }}> {chat.chatSettings.conversationId}</h2>

                    <h2 className="homeConvDescription">{chat.chatSettings.convoDescription}</h2>

                    <h2 className="homeConvDescription" style={{ marginTop: vh(4), fontSize: vh(2.75) }}>skills you will practice in this lesson:</h2>

                    <div className="homeInfoskills">
                        {chat.chatSettings.skills.map((skill, index) => {
                            let col = colors.length - 1
                            let colorDisplayId = index - Math.floor(index / col) * col;
                            return (
                                <div
                                    key={index}
                                    style={{ backgroundColor: colors[colorDisplayId] }}
                                    className="homeInfoskill">
                                    <h2>
                                        {skill}
                                    </h2>
                                </div>
                            )
                        })}
                    </div>
                </div>

                {/* {!loading ?
                    <h2
                        onClick={() => setLoadDisplay(false)}
                        className="loadTitle"
                        style={{ marginTop: vh(5), textDecoration: 'underline', cursor: 'pointer' }}> Start conversation</h2> : <></>
                } */}
            </div >

        </div >
    );
};

export default ChatLoader;
