import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import WarningPopup from "../../popups/admin/WarningPopup";

const CourseDetailsPopup = ({ course, open, close, save, saving }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [active, setActive] = useState(false);

  const [warningPopupOpen, setWarningPopupOpen] = useState(false);

  useEffect(() => {
    setName(course?.name ?? "");
    setDescription(course?.description ?? "");
    setActive(course?.is_active ?? false);
  }, [course]);

  const hasUnsavedChanges = useMemo(() => {
    return (
      name !== course.name ||
      description !== course.description ||
      active !== course.is_active
    );
  }, [name, description, active, course]);

  const closeAndReset = () => {
    setName(course?.name ?? "");
    setDescription(course?.description ?? "");
    setActive(course?.is_active ?? false);
    close();
  };

  return (
    <>
      <Dialog open={open} maxWidth="md" fullWidth>
        <Box sx={{ ml: 5, mr: 5 }}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6">
            DETAILS FOR COURSE: {course.name}
          </Typography>
          <div className="prompt-generation-input">
            <label htmlFor="course-name">Course name</label>
            <textarea
              className="prompt-generation-textarea"
              style={{ height: "fit-content" }}
              name="course-name"
              placeholder="Enter course name..."
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <br />
            <br />
          </div>
          <div className="prompt-generation-input">
            <label htmlFor="course-description">Course description</label>
            <textarea
              className="prompt-generation-textarea"
              name="course-description"
              placeholder="Enter course description..."
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <br />
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={active}
                  onChange={() => setActive((prev) => !prev)}
                  sx={{
                    "&.Mui-checked": {
                      color: "#0ae5a1",
                    },
                  }}
                />
              }
              label="Active"
              labelPlacement="start"
              sx={{ m: 0, fontFamily: "Assistant", fontSize: "1rem" }}
            />
          </div>
          <DialogActions sx={{ mt: 1, mb: 1, justifyContent: "flex-start" }}>
            <button
              className="prompt-generation-button"
              onClick={() => {
                if (hasUnsavedChanges) {
                  setWarningPopupOpen(true);
                } else close();
              }}
              style={{ marginLeft: 0 }}
            >
              CANCEL
            </button>
            <button
              className="prompt-generation-button"
              style={{ backgroundColor: "#0ae5a1" }}
              onClick={() => {
                save({
                  ...course,
                  name: name,
                  description: description,
                  is_active: active
                });
                close();
              }}
              disabled={saving}
            >
              {saving ? "SAVING..." : "SAVE"}
            </button>
          </DialogActions>
        </Box>
      </Dialog>
      <WarningPopup
        open={warningPopupOpen}
        confirm={() => closeAndReset()}
        close={() => setWarningPopupOpen(false)}
        confirmText="CLOSE"
      >
        Are you sure you want to close the editing window?
        <br />
        WARNING: this will discard your changes
      </WarningPopup>
    </>
  );
};

export default CourseDetailsPopup;
