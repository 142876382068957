import PageHeader from "../header";
import AdminSidebar from "./AdminSidebar";

const AdminPage = ({ currentLocation, children }) => {
  return (
    <div className="admin-page">
      <PageHeader />
      <div className="admin-page-sidebar-and-content">
        <AdminSidebar currentLocation={currentLocation} />
        <div className="admin-page-content">
          {children}
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
