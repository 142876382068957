import axios from "axios";
import { URL } from "../../../tools/url";
import { decodeJSONFromServer } from "../../../tools/admin_enums";
import { useEffect, useState } from "react";
import useErrorPopup from "../../../tools/hooks/showError";
import { Chip } from "@mui/material";

const GeneratePrompts = ({
  prompts,
  MAX_PROMPTS_LENGTH,
  builder,
  complete,
  disabled,
  generating,
  setGenerating,
}) => {
  const [numPromptsToGenerate, setNumPromptsToGenerate] = useState(1);
  const [llmList, setLlmList] = useState([]);
  const [llms, setLlms] = useState([]);
  const [template, setTemplate] = useState("");

  const [showError, ErrorPopupComponent] = useErrorPopup();

  useEffect(() => {
    setLlmList(builder?.out_supported_llms ?? []);
    setNumPromptsToGenerate(builder?.in_num_of_prompts_to_generate ?? 1);
    setLlms(builder?.in_chosen_llms ?? []);
    setTemplate(builder?.prompt_to_generate_prompts ?? "");
  }, [builder]);

  const chipSx = (selected) => {
    const basicObj = {
      m: 0.5,
      border: "1px solid #282c34",
    };
    return selected
      ? {
          ...basicObj,
          bgcolor: "#282c34",
          color: "#ffffff",
          "&:hover, &:active": {
            bgcolor: "#282c34",
          },
        }
      : {
          ...basicObj,
          "&:hover, &:active": {
            bgcolor: "inherit",
          },
        };
  };

  const generatePrompts = async () => {
    if (builder?.question?.definition) {
      setGenerating(true);
      try {
        const { data } = await axios.post(
          `${URL}/api/classification_builder_generate_prompts/`,
          {
            param_builder: {
              ...builder,
              in_num_of_prompts_to_generate: numPromptsToGenerate,
              in_chosen_llms: llms,
              prompt_to_generate_prompts: template,
            },
          }
        );
        const decodedData = decodeJSONFromServer(data);
        complete({ ...decodedData });
      } catch (err) {
        console.error(err);
        showError("Couldn't generate prompts");
      }
      setGenerating(false);
    }
  };

  return (
    <>
      <div
        style={{
          padding: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            padding: "10px",
          }}
        >
          <div style={{ width: "50%", paddingLeft: "15px" }}>
            Number of prompts to generate ({numPromptsToGenerate}):
            <br />
            <input
              disabled={generating}
              type="number"
              value={numPromptsToGenerate}
              onChange={(e) => {
                if (
                  e.target.value <= MAX_PROMPTS_LENGTH - prompts.length &&
                  e.target.value >= 1
                )
                  setNumPromptsToGenerate(e.target.value);
              }}
              min={1}
              max={MAX_PROMPTS_LENGTH - prompts.length}
              className="generate-popup-number-input"
            />
          </div>
          <div style={{ width: "50%", paddingLeft: "15px" }}>
            Choose LLMs
            <div
              style={{ display: "flex", flexWrap: "wrap", paddingTop: "4.5px" }}
            >
              {llmList.map((item) => (
                <Chip
                  sx={chipSx(llms.includes(item))}
                  key={item}
                  label={item}
                  disabled={disabled}
                  onClick={() =>
                    setLlms((prev) =>
                      prev.some((i) => i === item)
                        ? prev.filter((i) => i !== item)
                        : [...prev, item]
                    )
                  }
                />
              ))}
            </div>
          </div>
        </div>
        <div style={{ padding: "15px" }}>
          <div>Prompt template:</div>
          <textarea
            className="prompt-generation-textarea"
            value={template}
            onChange={(e) => setTemplate(e.target.value)}
            style={{
              height: "200px",
              marginTop: "5px",
            }}
          ></textarea>
        </div>
      </div>
      <button
        disabled={generating || !numPromptsToGenerate || !llms.length}
        className="prompt-generation-button generate-tests-button"
        onClick={generatePrompts}
        title={
          generating
            ? "Generating..."
            : !numPromptsToGenerate
            ? "Must generate at least 1 prompt"
            : !llms.length
            ? "Choose at least one LLM"
            : ""
        }
      >
        {generating ? "GENERATING..." : "GENERATE"}
      </button>
      {ErrorPopupComponent}
    </>
  );
};

export default GeneratePrompts;
