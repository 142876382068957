//packages
import React, { useEffect, useState } from "react";
import axios from "axios";
//style
import '../../style/reviewPopup.css';
//consts
import { URL } from "../../tools/url";
//stores
import chat from "../../stores/chat.store";
import { vh, vw } from "../../tools/screen";

const ParamsReviewPopup = ({ params, userTurn, close }) => {

    const [paramValues, setParamValues] = useState([]);

    useEffect(() => {
        let tempParamList = params.map((param) => {
            return ({ open: false, value: '' })
        })
        setParamValues(tempParamList)
    }, [params])


    const handleFeedbackReview = async () => {
        let sendVal = []
        paramValues.forEach((param, index) => {
            if (param.value) {
                sendVal.push({ paramName: params[index].paramName, paramId: params[index].paramId, expectedResult: param.value })
            }
        })
        //closes the popup
        close(false);
        await axios.post(`${URL}/api/reportSocialParam/`,
            {
                'socialParams': sendVal,
                'userTurnId': userTurn,
                'sessionId': chat.chatSettings.sessionId
            });

    }

    const toggleDropdown = (index) => {
        let tempArr = [...paramValues];
        paramValues[index].open = !paramValues[index].open;
        setParamValues(tempArr)
    }
    const selectParamOption = (index, option) => {
        let tempArr = [...paramValues];
        paramValues[index].value = option;
        paramValues[index].open = false;
        setParamValues(tempArr)

    }
    return (
        <div className="popupContainer">
            <img
                onClick={() => { close(false); }}
                className="close"
                src="/images/x_button.png"
                alt="x"
            />
            <h2 className="revTitle" style={{ fontWeight: "bold", paddingLeft: vw(2) }}>Review the following social params:</h2>
            <div style={{ maxHeight: vh(31), overflowY: 'auto', width: vw(43), marginLeft: vw(2) }}>

                {params.map((param, index) => {
                    return (
                        <div style={{ display: 'flex', width: vw(41), flexDirection: 'row', justifyContent: 'space-between', backgroundColor: 'rgba(38,45,59,.18)', borderRadius: vh(2), marginTop: vh(1), paddingBottom: vh(1) }}>
                            <h3 className="paramText">{param.paramName}</h3>
                            <div className="dropDownContainer" style={{ marginRight: vw(1) }}>
                                <div
                                    className="reviewOpt"
                                    onClick={() => { toggleDropdown(index) }}
                                >
                                    <h2>
                                        {paramValues[index]?.value || 'opt.'}
                                    </h2>
                                    <img
                                        src="/images/dropdown.png"
                                        alt="dropdown"
                                    />
                                </div>
                                {param.paramOptions.map((opt) => {
                                    return (
                                        <>
                                            {paramValues[index]?.open && paramValues[index]?.value !== opt ?
                                                <div
                                                    key={opt}
                                                    className="reviewOpt"
                                                    onClick={() => {
                                                        selectParamOption(index, opt)
                                                    }}
                                                >
                                                    <h2>
                                                        {opt}
                                                    </h2>
                                                </div>
                                                :
                                                <></>}
                                        </>

                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>

            <div onClick={() => { handleFeedbackReview() }} className="sendReview">
                <h2>Send review</h2>
            </div>
        </div>
    );
};

export default ParamsReviewPopup;
