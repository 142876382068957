import AdminPage from "../components/admin/AdminPage";

const AdminHome = () => {
  return (
    <AdminPage currentLocation="Home">
      <div className="prompt-generation-container">
        <h1>Admin home</h1>
      </div>
    </AdminPage>
  );
};

export default AdminHome;
