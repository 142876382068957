//packages
import React from "react";
import { useNavigate } from "react-router-dom";
//style
import '../../style/googleFormSubmit.css';
//tools
import { vh } from "../../tools/screen"
import Button from "../button";


const GoogleFormSubmitPopup = ({ startConv }) => {
    const navigate = useNavigate();
    const closeAndNavigate = async () => {
        navigate("../")
    }

    return (
        <div className="megaInfoContainer">

            <div className="popupContainerForm">
                <img className="doneImg" src="./images/formSubmitted.png" alt="good job symbol" />

                <div className="centerdForm">
                    <h2 className="formSubmitTxt">Form submitted!</h2>
                    <h2 className="formSubmitTxtS">We have your information, so now sit back, and enjoy a version of Arrows made just for you!</h2>

                </div>
                <Button
                    text='Get started with Arrows'
                    icon='./images/playWhite.png'
                    style={{ marginTop: vh(5), marginBottom: vh(5), backgroundColor: '#262D3B', fontWeight: '800', alignSelf: 'center' }}
                    alt='start'
                    onClick={() => { closeAndNavigate() }}
                />

            </div>
        </div>
    );
};

export default GoogleFormSubmitPopup;
