import axios from "axios";
import { useEffect, useState } from "react";
import { URL } from "../../../tools/url";
import ClassificationQuestionsList from "../classification-question/ClassificationQuestionsList";
import { Checkbox, FormControlLabel } from "@mui/material";
import useErrorPopup from "../../../tools/hooks/showError";

const EnterSkillData = ({ active, skill, complete, skillsListFlat }) => {
  const [skillName, setSkillName] = useState("");
  const [skillDescription, setSkillDescription] = useState("");
  const [skillActive, setSkillActive] = useState(true);
  const [failureCue, setFailureCue] = useState("");
  const [failureMessage, setFailureMessage] = useState("");
  const [failureExplanation, setFailureExplanation] = useState("");
  const [cueQuestionId, setCueQuestionId] = useState(null);
  const [posFeedbackQuestionId, setPosFeedbackQuestionId] = useState(null);
  const [potentialParents, setPotentialParents] = useState([]);
  const [parentId, setParentId] = useState(null);

  const [sendingSkill, setSendingSkill] = useState(false);

  const [showError, ErrorPopupComponent] = useErrorPopup();

  useEffect(() => {
    setSkillName(skill?.name || "");
    setSkillDescription(skill?.description || "");
    setSkillActive(skill?.is_active || false);
    setFailureCue(skill?.failure_cue || "");
    setFailureMessage(skill?.failure_message || "");
    setFailureExplanation(skill?.failure_explanation || "");
    setCueQuestionId(skill?.cue_question_id || null);
    setPosFeedbackQuestionId(skill?.pos_feedback_question_id || null);
    setParentId(skill?.parent_id || null);
  }, [skill]);

  useEffect(() => {
    let descendants = [];
    function findDescendants(parentId) {
      // Find all children of the given parent ID
      const children = skillsListFlat.filter(
        (item) => item.parent_id === parentId
      );

      children.forEach((child) => {
        descendants.push(child);
        // Recursively find descendants of the current child
        findDescendants(child.id);
      });
    }

    if (skill && skillsListFlat?.length && skill.id) {
      findDescendants(skill.id);
    }

    setPotentialParents([
      { id: 0, name: "no parent" },
      ...skillsListFlat.filter(
        (item) =>
          !descendants.some((i) => i.id === item.id) && item.id !== skill.id
      ),
    ]);
  }, [skill, skillsListFlat]);

  const sendEnteredSkill = async () => {
    try {
      setSendingSkill(true);
      // TODO validation
      await axios.post(`${URL}/api/save_skill/`, {
        id: skill.id ?? null,
        name: skillName,
        description: skillDescription,
        is_active: skillActive,
        failure_cue: failureCue,
        failure_message: failureMessage,
        failure_explanation: failureExplanation,
        cue_question_id: cueQuestionId,
        pos_feedback_question_id: posFeedbackQuestionId,
        parent_id: parentId || null,
        owner_id: skill.owner_id ?? null,
      });
      complete();
    } catch (err) {
      console.error(err);
      showError("Couldn't save skill");
    } finally {
      setSendingSkill(false);
    }
  };

  return (
    <div>
      <h4 style={{ textAlign: "center", marginBottom: "5px" }}>
        {skill?.id
          ? `Details for skill: ${skill?.name}`
          : `New skill (${
              skill?.parent_id
                ? `child skill of "${
                    skillsListFlat.find((s) => s.id === skill.parent_id).name
                  }"`
                : "no parent skill"
            })`}
      </h4>
      {skill?.owner_id ? (
        <h5 style={{ textAlign: "center", marginBottom: "5px" }}>
          Owner id: {`${skill?.owner_id}`}
        </h5>
      ) : (
        <></>
      )}
      <br />
      <div className="prompt-generation-test-expected-result">
        Parent:{" "}
        <select
          value={parentId || 0}
          onChange={(e) => setParentId(Number(e.target.value))}
          style={{ width: "700px" }}
        >
          {potentialParents.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
      <br />
      <div className="prompt-generation-input">
        <label htmlFor="skill-name">
          Skill name <span style={{ color: "#fa7d5f" }}>*</span>
        </label>
        <textarea
          disabled={!active}
          className="prompt-generation-textarea"
          style={{ height: "fit-content" }}
          name="skill-name"
          placeholder="Enter skill name..."
          value={skillName}
          onChange={(e) => setSkillName(e.target.value)}
        />
      </div>
      <br />
      <div className="prompt-generation-input">
        <label htmlFor="skill-description">Skill description</label>
        <textarea
          disabled={!active}
          className="prompt-generation-textarea"
          name="skill-description"
          placeholder="Enter skill description..."
          value={skillDescription}
          onChange={(e) => setSkillDescription(e.target.value)}
        />
      </div>
      <br />
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={skillActive}
              onChange={() => setSkillActive((prev) => !prev)}
              sx={{
                "&.Mui-checked": {
                  color: "#0ae5a1",
                },
              }}
            />
          }
          label="Active"
          labelPlacement="start"
          sx={{ m: 0 }}
        />
      </div>
      <br />
      <div className="prompt-generation-input">
        <label htmlFor="failure-cue">Failure cue</label>
        <textarea
          disabled={!active}
          className="prompt-generation-textarea"
          style={{ height: "fit-content" }}
          name="failure-cue"
          placeholder="Enter failure cue..."
          value={failureCue}
          onChange={(e) => setFailureCue(e.target.value)}
        />
      </div>
      <br />
      <div className="prompt-generation-input">
        <label htmlFor="failure-message">Failure message</label>
        <textarea
          disabled={!active}
          className="prompt-generation-textarea"
          name="failure-message"
          style={{ height: "fit-content" }}
          placeholder="Enter failure message..."
          value={failureMessage}
          onChange={(e) => setFailureMessage(e.target.value)}
        />
      </div>
      <br />
      <div className="prompt-generation-input">
        <label htmlFor="failure-explanation">Failure explanation</label>
        <textarea
          disabled={!active}
          className="prompt-generation-textarea"
          name="failure-explanation"
          style={{ height: "fit-content" }}
          placeholder="Enter failure explanation..."
          value={failureExplanation}
          onChange={(e) => setFailureExplanation(e.target.value)}
        />
      </div>
      <br />
      <div>
        Cue question: <br />
        <br />
        <ClassificationQuestionsList
          radio
          setChosenQuestion={(cq) => setCueQuestionId(cq ? cq.id : null)}
          chosenQuestion={{ id: cueQuestionId }}
        />
      </div>
      <br />
      <div>
        Positive feedback question: <br />
        <br />
        <ClassificationQuestionsList
          radio
          setChosenQuestion={(cq) =>
            setPosFeedbackQuestionId(cq ? cq.id : null)
          }
          chosenQuestion={{ id: posFeedbackQuestionId }}
        />
      </div>
      <br />
      <button
        disabled={!active || !skillName || sendingSkill}
        className="prompt-generation-button"
        onClick={sendEnteredSkill}
      >
        {active
          ? sendingSkill
            ? "SAVING SKILL..."
            : "SAVE SKILL"
          : "SKILL SAVED"}
      </button>
      {ErrorPopupComponent}
    </div>
  );
};

export default EnterSkillData;
