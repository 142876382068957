import { useState } from "react";
import { QuestionMark } from "@mui/icons-material";
import useErrorPopup from "../../../../tools/hooks/showError";
import ScenarioConditionInList from "./ScenarioConditionInList";
import axios from "axios";
import { URL } from "../../../../tools/url";

const ScenarioConditionList = ({
  scenario,
  setScenario,
  save,
  loading,
  saving,
}) => {
  const [editingNew, setEditingNew] = useState(false);
  const [savingNew, setSavingNew] = useState(false);
  const [newScenarioConditionName, setNewScenarioConditionName] = useState("");
  const [showError, ErrorPopupComponent] = useErrorPopup();

  const updateScenarioCondition = (
    scenarioConditionData,
    isNew = false,
    deleteScenarioCondition = false
  ) => {
    setScenario({
      ...scenario,
      conditions: isNew
        ? [...scenario.conditions, scenarioConditionData]
        : deleteScenarioCondition
        ? scenario.conditions.filter((s) => s.id !== scenarioConditionData.id)
        : scenario.conditions.map((s) => {
            if (s.id === scenarioConditionData.id) {
              return scenarioConditionData;
            }
            return s;
          }),
    });
  };

  const saveScenarioCondition = async (scenarioConditionData, isNew = false) => {
    try {
      const { data } = await axios.post(`${URL}/api/ceSaveScenarioCondition/`, {
        ...scenarioConditionData,
      });
      updateScenarioCondition(data, isNew);
    } catch (err) {
      console.error(err);
      showError("Couldn't save scenario condition");
    }
  };

  const saveNewScenarioCondition = () => {
    setSavingNew(true);
    saveScenarioCondition(
      {
        parent_scenario_id: scenario.id,
        name: newScenarioConditionName,
      },
      true
    );
    setEditingNew(false);
    setSavingNew(false);
    setNewScenarioConditionName("");
  };

  return (
    <>
      <div className="prompt-generation-section">
        <h2 style={{ marginBottom: "10px" }}>Scenario conditions</h2>
        <div className="staff-course-list scenario-state-list">
          {scenario?.conditions ? (
            scenario.conditions.length ? (
              <>
                {scenario.conditions.map((scenarioCondition) => (
                  <ScenarioConditionInList
                    key={scenarioCondition.id}
                    scenarioCondition={scenarioCondition}
                    saveScenarioCondition={saveScenarioCondition}
                    updateScenarioCondition={updateScenarioCondition}
                    allScenarioStates={scenario.states}
                    saving={saving}
                  />
                ))}
              </>
            ) : (
              <div className="staff-course-in-list">
                No scenario conditions available
              </div>
            )
          ) : (
            <div className="staff-course-in-list">
              <div className="loader prompt-generation-loader">
                <div id="bar1" className="bar"></div>
                <div id="bar2" className="bar"></div>
                <div id="bar3" className="bar"></div>
              </div>
            </div>
          )}
          <div
            className="staff-course-in-list"
            style={{
              margin: "10px 0",
              background: "#7ee2b8",
              display: "flex",
              alignItems: "center",
              height: "50px",
              padding: "0 15px",
            }}
          >
            {editingNew ? (
              <div
                className="staff-course-in-list-name"
                style={{ display: "flex", alignItems: "center" }}
              >
                <QuestionMark />
                <input
                  placeholder="Enter scenario condition name..."
                  value={newScenarioConditionName}
                  onChange={(e) => setNewScenarioConditionName(e.target.value)}
                  style={{ width: "600px", boxSizing: "border-box" }}
                  autoFocus
                ></input>
                {savingNew ? (
                  <div
                    style={{
                      width: "200px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className="loader prompt-generation-loader"
                      style={{ height: "35px" }}
                    >
                      <div id="bar1" className="bar"></div>
                      <div id="bar2" className="bar"></div>
                      <div id="bar3" className="bar"></div>
                    </div>
                  </div>
                ) : (
                  <>
                    <button
                      className="prompt-generation-button"
                      style={{
                        margin: "0 5px 0 0",
                        padding: "5px 10px",
                        background: false ? "#ccc" : "#0ae5a1",
                        border: "1px solid #282c34",
                        color: "#282c34",
                      }}
                      disabled={false}
                      onClick={saveNewScenarioCondition}
                    >
                      SAVE
                    </button>
                    <button
                      className="prompt-generation-button"
                      style={{
                        margin: 0,
                        padding: "5px 10px",
                        background: "#fa7d5f",
                        border: "1px solid #282c34",
                        color: "#282c34",
                      }}
                      onClick={() => {
                        setEditingNew(false);
                        setNewScenarioConditionName("");
                      }}
                    >
                      CANCEL
                    </button>
                  </>
                )}
              </div>
            ) : (
              <button
                className="prompt-generation-button"
                style={{
                  margin: 0,
                  padding: "5px 10px",
                }}
                onClick={() => setEditingNew(true)}
              >
                ADD A NEW CONDITION
              </button>
            )}
          </div>
        </div>
      </div>
      {ErrorPopupComponent}
    </>
  );
};

export default ScenarioConditionList;
