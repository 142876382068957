import * as React from 'react';
import { useState, useEffect } from "react";
import axios from "axios";
import { URL } from "../../../tools/url";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckIcon from '@mui/icons-material/Check';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import CircleIcon from '@mui/icons-material/Circle';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { useTreeItem2Utils } from '@mui/x-tree-view/hooks';
import { TreeItem2 } from '@mui/x-tree-view/TreeItem2';
import { TreeItem2LabelInput } from '@mui/x-tree-view/TreeItem2LabelInput';
import { useTreeViewApiRef } from '@mui/x-tree-view/hooks';
import Typography from '@mui/material/Typography';
import "../../../style/llmManagement.css"
import useErrorPopup from "../../../tools/hooks/showError";
import AddModelPopup from "./addModelPopup";
import TestResultsPopup from "./testsResultsPopup";

// Tree label layout
function CustomLabel({ editing, editable, children, status, onClick, className, toggleItemEditing, secondaryLabel,
                        selectable, ...other }) {
  return (
    <div className={className}>
      {secondaryLabel && (
        <Typography variant="caption" color="secondary" sx={{ fontSize: 15 }}>
          {secondaryLabel}
        </Typography>
      )}
      <Typography
        {...other}
        editable={editable}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          justifyContent: 'space-between',
        }}>
        {children}
        {editable && (
          <>
            <IconButton
              size="small"
              onClick={toggleItemEditing}
              sx={{ color: 'text.secondary', position: 'absolute', right: 60, }}
            >
              <EditOutlinedIcon fontSize="medium" />
            </IconButton>
            { selectable && (
              <IconButton onClick={onClick} aria-label="select item" size="small"
                          sx={{ color: 'text.secondary', position: 'absolute', right: 20, }}>
                {status.selected ? (
                    <CircleIcon fontSize="medium" color="primary" />
                  ) : (
                    <PanoramaFishEyeIcon fontSize="medium" color="primary" />
                  )}
              </IconButton>
              ) }
          </>
        )}
      </Typography>
    </div>
  );
}

const ERRORS = {
  REQUIRED: 'The label cannot be empty',
  INVALID: 'The label must be a number',
};

// Tree editing layout
function CustomLabelInput(props) {
  const { handleCancelItemLabelEditing, handleSaveItemLabel, value, error, ...other } = props;

  return (
    <React.Fragment>
      {props.secondaryLabel && (
        <Typography variant="caption" color="secondary" sx={{ fontSize: 15 }}>
          {props.secondaryLabel}
        </Typography>
      )}
      {error ? (
          <Tooltip title={ERRORS[error]}>
            <ErrorOutlineIcon color="error" />
          </Tooltip>
        ) : (
          <Tooltip title="All good!">
            <CheckCircleOutlineIcon color="success" />
          </Tooltip>
        )}
      <TreeItem2LabelInput {...other} value={value} />
      <IconButton color="success" size="small"
        onClick={(event) => {
          handleSaveItemLabel(event, value);
        }}>
        <CheckIcon fontSize="small" />
      </IconButton>
      <IconButton color="error" size="small" onClick={handleCancelItemLabelEditing}>
        <CloseRoundedIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
}

// Tree item flow
const CustomTreeItem2 = React.forwardRef(function CustomTreeItem2(props, ref) {
  const [error, setError] = React.useState(null);
  const { interactions, status, publicAPI } = useTreeItem2Utils({
    itemId: props.itemId,
    children: props.children,
  });

  const item = publicAPI.getItem(props.itemId);

  const handleContentClick = (event) => {
    event.defaultMuiPrevented = true;
  };

  const handleContentDoubleClick = (event) => {
    event.defaultMuiPrevented = true;
  };

  const validateInput = (value) => {
    if (!value) {
      setError('REQUIRED');
    } else if (isNaN(value) || isNaN(parseFloat(value))) {
      setError('INVALID');
    } else {
      setError(null);
    }
  };

  const handleInputBlur = (event) => {
    if (error) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleInputKeyDown = (event) => {
    event.defaultMuiPrevented = true;
    if (event.key === 'Enter' && event.target.value) {
      if (error) {
        return;
      }
      setError(null);
      interactions.handleSaveItemLabel(event, event.target.value);
    } else if (event.key === 'Escape') {
      setError(null);
      interactions.handleCancelItemLabelEditing(event);
    }
  };

  const handleInputChange = (event) => {
    if (item.secondaryLabel === 'Input cost per million tokens (USD)' ||
        item.secondaryLabel === 'Output cost per million tokens (USD)') {
      validateInput(event.target.value);
    }
  };

  const handleIconButtonClick = (event) => {
    interactions.handleSelection(event);
  };

  return (
    <TreeItem2
      {...props}
      ref={ref}
      slots={{ label: CustomLabel, labelInput: CustomLabelInput }}
      slotProps={{
        label: {
          onDoubleClick: handleContentDoubleClick,
          editable: status.editable,
          editing: status.editing,
          toggleItemEditing: interactions.toggleItemEditing,
          secondaryLabel: item?.secondaryLabel || '',
          onClick: handleIconButtonClick,
          selectable: item?.selectable,
          status
        },
        content: { onClick: handleContentClick },
        labelInput: {
          handleCancelItemLabelEditing: interactions.handleCancelItemLabelEditing,
          handleSaveItemLabel: interactions.handleSaveItemLabel,
          onBlur: handleInputBlur,
          onKeyDown: handleInputKeyDown,
          onChange: handleInputChange,
          error,
        },
      }}
    />
  );
});


export default function LLMTree({ LLMs, updateTree }) {
  const apiRef = useTreeViewApiRef();
  const [newValue, setNewValue] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [selectedLLM, setSelectedLLM] = useState(null);
  const [showAddModel, setShowAddModel] = useState(false);
  const [showTestResults, setShowTestResults] = useState(false);
  const [currentModel, setCurrentModel] = useState(null);
  const [showError, ] = useErrorPopup();

  // update the DB using the new value
  const updateDB = async (value) => {
    try {
      const index = value.itemId.indexOf("_");
      const id = value.itemId.slice(0, index);
      const type = value.itemId.slice(index + 1);
      if (type === "model_version" || type === "model_family" || type === "provider") {
        await axios.get(`${URL}/api/edit_llm_model/?llm_id=${id}&llm_type=${type}&field=name&new_value=${value.label}`);
      } else if (type === "input_cost_per_million_tokens" || type === "output_cost_per_million_tokens") {
        await axios.get(`${URL}/api/edit_llm_model/?llm_id=${id}&llm_type=model_family&field=${type}&new_value=${value.label}`);
      } else if (type === "import_code_line" || type === "llm_class_name") {
        await axios.get(`${URL}/api/edit_llm_model/?llm_id=${id}&llm_type=provider&field=${type}&new_value=${value.label}`);
      }
    } catch (err) {
      console.error(err);
      showError("Couldn't update database entry");
    }
  };

  // update the current selected model and model version
  const handleLLMSelectionToggle = (event, itemId) => {
    if (itemId == null) {
      setSelectedVersion(null);
      setSelectedLLM(null);
    } else {
      let secondaryLabel = apiRef.current.getItem(itemId).secondaryLabel;
      if (secondaryLabel !== "Model version") {
        setSelectedVersion(null);
      } else {
        setSelectedVersion(apiRef.current.getItem(itemId));
      }
      if (secondaryLabel === "Provider" || secondaryLabel === "Model family" || secondaryLabel === "Model version") {
        setSelectedLLM(apiRef.current.getItem(itemId));
      } else {
        setSelectedLLM(null);
      }
    }
  };

  // show the add model popup
  const addModel = () => {
    setShowAddModel(true);
  };

  // delete the selected model
  const deleteModel = async () => {
    try {
      const index = selectedLLM.id.indexOf("_");
      const id = selectedLLM.id.slice(0, index);
      const type = selectedLLM.id.slice(index + 1);
      await axios.get(`${URL}/api/delete_llm_model/?llm_id=${id}&llm_type=${type}`);
      setSelectedVersion(null);
      setSelectedLLM(null);
      updateTree()
    } catch (err) {
      console.error(err);
      showError("Couldn't delete database entry");
    }
  };

  // show the run tests popup
  const runTests = async () => {
    try {
      setShowTestResults(true);
    } catch (err) {
      console.error(err);
      showError("Couldn't run tests");
    }
  };

  // set the chat's LLM model
  const selectChatModel = async () => {
    try {
      const index = selectedVersion.id.indexOf("_");
      const id = selectedVersion.id.slice(0, index);
      await axios.get(`${URL}/api/select_llm_model/?model_version=${id}`);
      getCurrentChatModel();
    } catch (err) {
      console.error(err);
      showError("Couldn't select model");
    }
  };

  // get the current chat model running on server
  const getCurrentChatModel = async () => {
    try {
      const res = await axios.get(`${URL}/api/get_current_chat_model`);
      setCurrentModel(res.data.model_version);
    } catch (err) {
      console.error(err);
      showError("Couldn't get current chat model");
    }
  };

  // update the DB if a value has been changed
  useEffect(() => {
    if (newValue) {
      updateDB(newValue);
    }
  }, [newValue]);

  // get the current chat model running on server
  useEffect(() => {
    getCurrentChatModel();
  }, []);

  return (
    <>
      { showAddModel ? (
          <AddModelPopup updateTree={updateTree} showPopup={setShowAddModel} />
          ) : ( <></> ) }
      { showTestResults ? (
          <TestResultsPopup updateTree={updateTree} showPopup={setShowTestResults} newModel={selectedVersion} />
          ) : ( <></> ) }
      <Stack spacing={2} sx={{mr: 5 }}>
        <Typography>
          { currentModel ? (
            <label className="currentModel">
              Current chat model running on server: Provider - {currentModel.parent_model_family.parent_provider.name}, Model family - {currentModel.parent_model_family.name}, Model version - {currentModel.name}
            </label>
          ) : ( <></> ) }
        </Typography>
        <Box sx={{ minHeight: 300, minWidth: 260 }}>
          <RichTreeView
            items={LLMs}
            apiRef={apiRef}
            slots={{ item: CustomTreeItem2 }}
            experimentalFeatures={{ labelEditing: true }}
            isItemEditable={(item) => Boolean(item?.editable)}
            onItemLabelChange={(itemId, label) => setNewValue({ itemId, label })}
            onSelectedItemsChange={handleLLMSelectionToggle}
            itemChildrenIndentation={24}
          />
        </Box>
        <Typography>
          <label className="currentModel">
            Selected LLM model version: { selectedVersion == null ? 'No LLM model version selected'
                                            : `${selectedVersion.label}` }
          </label>
        </Typography>
        <Typography>
          <div className="llmButtons">
            <button className="llmButton" onClick={runTests} disabled={ selectedVersion ? false : true }>
              Run test with the selected model version
            </button>
            <button className="llmButton" onClick={addModel}>Add new LLM</button>
            <button className="llmButton" onClick={selectChatModel} disabled={ selectedVersion ? false : true }>
              Select as chat model
            </button>
            <button className="llmButtonRed" onClick={deleteModel} disabled={ selectedLLM ? false : true }>
              Delete selected LLM
            </button>
          </div>
        </Typography>
      </Stack>
    </>
  );
}
