import AdminPage from "../components/admin/AdminPage";
import CourseList from "../components/admin/course/CourseList";

const StaffCourses = () => {
  return (
    <AdminPage currentLocation="Courses">
      <div className="prompt-generation-container">
        <h1>Courses</h1>
        <br />
        <CourseList />
      </div>
    </AdminPage>
  );
};

export default StaffCourses;
