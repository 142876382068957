import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import WarningPopup from "../../popups/admin/WarningPopup";
import InfoPageEditor from "./text-editor/InfoPageEditor";

const InfoPageDetailsPopup = ({ infoPage, open, close, save, saving }) => {
  const [name, setName] = useState("");
  const [active, setActive] = useState(false);
  const [content, setContent] = useState("");

  const [warningPopupOpen, setWarningPopupOpen] = useState(false);

  useEffect(() => {
    setName(infoPage?.name ?? "");
    setActive(infoPage?.is_active ?? false);
    setContent(infoPage?.content ?? "");
  }, [infoPage]);

  const hasUnsavedChanges = useMemo(() => {
    return name !== infoPage.name || active !== infoPage.is_active;
  }, [name, active, infoPage]);

  const closeAndReset = () => {
    setName(infoPage?.name ?? "");
    setActive(infoPage?.is_active ?? false);
    setContent(infoPage?.content ?? "");
    close();
  };

  return (
    <>
      <Dialog open={open} maxWidth="lg" fullWidth>
        <Box sx={{ ml: 5, mr: 5 }}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6">
            DETAILS FOR INFO PAGE: {infoPage.name}
          </Typography>
          <div className="prompt-generation-input">
            <label htmlFor="info-page-name">Info page name</label>
            <textarea
              className="prompt-generation-textarea"
              style={{ height: "fit-content" }}
              name="info-page-name"
              placeholder="Enter info page name..."
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <br />
          <div>
            <label>Content</label>
            <InfoPageEditor
              initialHtml={infoPage.content}
              onHtmlChanged={(htmlContent) => setContent(htmlContent)}
            />
          </div>
          <br />
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={active}
                  onChange={() => setActive((prev) => !prev)}
                  sx={{
                    "&.Mui-checked": {
                      color: "#0ae5a1",
                    },
                  }}
                />
              }
              label="Active"
              labelPlacement="start"
              sx={{ m: 0, fontFamily: "Assistant", fontSize: "1rem" }}
            />
          </div>
          <DialogActions sx={{ mt: 1, mb: 1, justifyContent: "flex-start" }}>
            <button
              className="prompt-generation-button"
              onClick={() => {
                if (hasUnsavedChanges) {
                  setWarningPopupOpen(true);
                } else close();
              }}
              style={{ marginLeft: 0 }}
            >
              CANCEL
            </button>
            <button
              className="prompt-generation-button"
              style={{ backgroundColor: "#0ae5a1" }}
              disabled={saving}
              onClick={() => {
                save({
                  ...infoPage,
                  name: name,
                  is_active: active,
                  content: content,
                });
                close();
              }}
            >
              {saving ? "SAVING..." : "SAVE"}
            </button>
          </DialogActions>
        </Box>
      </Dialog>
      <WarningPopup
        open={warningPopupOpen}
        confirm={() => closeAndReset()}
        close={() => setWarningPopupOpen(false)}
        confirmText="CLOSE"
      >
        Are you sure you want to close the editing window?
        <br />
        WARNING: this will discard your changes
      </WarningPopup>
    </>
  );
};

export default InfoPageDetailsPopup;
