import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import WarningPopup from "../../../popups/admin/WarningPopup";
import ClassificationQuestionsList from "../../classification-question/ClassificationQuestionsList";

const ScenarioConditionsDetailsPopup = ({
  condition,
  open,
  close,
  save,
  saving,
}) => {
  const [name, setName] = useState("");
  const [active, setActive] = useState(false);
  const [classificationQuestion, setClassificationQuestion] = useState(null);

  const [warningPopupOpen, setWarningPopupOpen] = useState(false);

  useEffect(() => {
    setName(condition?.name ?? "");
    setActive(condition?.is_active ?? false);
    setClassificationQuestion(
      condition.question_id ? { id: condition.question_id } : null
    );
  }, [condition]);

  const hasUnsavedChanges = useMemo(() => {
    return (
      name !== condition.name ||
      active !== condition.is_active ||
      classificationQuestion?.id !== condition.question_id
    );
  }, [name, active, classificationQuestion, condition]);

  const closeAndReset = () => {
    setName(condition?.name ?? "");
    setActive(condition?.is_active ?? false);
    setClassificationQuestion(null);
    close();
  };

  return (
    <>
      <Dialog open={open} maxWidth="md" fullWidth>
        <Box sx={{ ml: 5, mr: 5 }}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6">
            DETAILS FOR CONDITION: {condition.name}
          </Typography>
          <div className="prompt-generation-input">
            <label htmlFor="condition-name">Scenario state name</label>
            <textarea
              className="prompt-generation-textarea"
              style={{ height: "fit-content" }}
              name="condition-name"
              placeholder="Enter scenario state name..."
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={saving}
            />
            <br />
          </div>
          <br />
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={active}
                  onChange={() => setActive((prev) => !prev)}
                  sx={{
                    "&.Mui-checked": {
                      color: "#0ae5a1",
                    },
                  }}
                />
              }
              label="Active"
              labelPlacement="start"
              sx={{ m: 0, fontFamily: "Assistant", fontSize: "1rem" }}
              disabled={saving}
            />
          </div>
          <br />
          <div style={{ color: "#282c34", marginBottom: "5px" }}>Choose a classification question</div>
          <ClassificationQuestionsList
            chosenQuestion={classificationQuestion}
            setChosenQuestion={setClassificationQuestion}
            radio
          />
          <DialogActions sx={{ mt: 1, mb: 1, justifyContent: "flex-start" }}>
            <button
              className="prompt-generation-button"
              onClick={() => {
                if (hasUnsavedChanges) {
                  setWarningPopupOpen(true);
                } else close();
              }}
              style={{ marginLeft: 0 }}
            >
              CANCEL
            </button>
            <button
              className="prompt-generation-button"
              style={{ backgroundColor: saving ? "#ccc" : "#0ae5a1" }}
              onClick={() => {
                save({
                  ...condition,
                  name: name,
                  is_active: active,
                  question_id: classificationQuestion?.id || null,
                });
                close();
              }}
              disabled={saving}
            >
              {saving ? "SAVING..." : "SAVE"}
            </button>
          </DialogActions>
        </Box>
      </Dialog>
      <WarningPopup
        open={warningPopupOpen}
        confirm={() => closeAndReset()}
        close={() => setWarningPopupOpen(false)}
        confirmText="CLOSE"
      >
        Are you sure you want to close the editing window?
        <br />
        WARNING: this will discard your changes
      </WarningPopup>
    </>
  );
};

export default ScenarioConditionsDetailsPopup;
