//packages
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import ReactGA from 'react-ga4';
//components
import PageHeader from '../components/header';
import Button from '../components/button';
//tools
import { vw, vh } from '../tools/screen';
//styles
import "../style/chapterSum.css";

const ChapterSum = () => {
    const navigate = useNavigate();
    const [content, setContent] = useState('')
    const [title, setTitle] = useState('')

    useEffect(() => {
        let contentTxt = localStorage.getItem("page_content")
        setContent(contentTxt)
        let titleTxt = localStorage.getItem("page_title")
        setTitle(titleTxt)
    }, [])

    return (
        <div style={{ width: vw(100), height: vh(100), overflow: 'hidden' }}>
            <PageHeader />
            <div className='chapterSumContainer'>
                <div className="back" onClick={() => {
                    localStorage.removeItem("page_content")
                    localStorage.removeItem("page_title")
                    if (localStorage.getItem("courseId")) navigate(`/course/${localStorage.getItem("courseId")}`);
                    else navigate("/");
                }}>
                    <img src="/images/back.png" alt="back" style={{ height: vh(2) }} />
                    <h2>Home</h2>
                </div>
                <div className='chapterTitle'>{title}</div>
                {content ?
                    <div className='mdContent'>
                        <Markdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} children={content} />
                    </div>
                    : <></>}

                <Button
                    text='Continue'
                    icon='./images/playWhite.png'
                    style={{
                        marginTop: vh(5),
                        marginBottom: vh(10),
                        marginLeft: vw(15),
                        backgroundColor: '#262D3B',
                    }}
                    alt='Continue'
                    onClick={() => {
                        ReactGA.event({
                            category: 'User',
                            action: 'reading complete'
                        });
                        localStorage.removeItem("page_content")
                        localStorage.removeItem("page_title")
                        if (localStorage.getItem("courseId")) navigate(`/course/${localStorage.getItem("courseId")}`);
                        else navigate("/");
                    }}
                />
            </div>

        </div >
    );
};

export default ChapterSum;
