import axios from "axios";
import { URL } from "../../../tools/url";
import { useState, useEffect } from "react";
import useErrorPopup from "../../../tools/hooks/showError";
import "../../../style/filterBar.css";
import Graph from "./Graph.jsx";
import { Line } from 'recharts';
import { groupBy, getAllSkillEvents, fillNullDates, GraphFilterOptions, GraphNames, SkillEventsTypes } from
"./KPIUtils.jsx";

const ProgressDisplay = ({ currentLocation, children }) => {
  const [skillOptions, setSkillOptions] = useState();
  const [userOptions, setUserOptions] = useState();
  const [graphData, setGraphData] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [showError, ErrorPopupComponent] = useErrorPopup();
  const [user, setUser] = useState(null);
  const [skill, setSkill] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [lines, setLines] = useState(<><Line type="monotone" dataKey="Cues" stroke="#eb4034" />
    <Line type="monotone" dataKey="Positive Feedback" stroke="#82ca9d" /></>);

  // get all the skills from the DB and insert them into the dropdown skill select
  const fetchSkills = async () => {
    try {
      const { data } = await axios.get(`${URL}/api/get_all_skills/`);
      setSkillOptions(data?.skills.map(skill => {
        if (skill.name.length) {
          return (<option key={skill.name} value={skill.name}>{skill.name}</option>);
        }}));
    } catch (err) {
      console.error(err);
      showError("Couldn't get skills");
    }
  };

  // get all the users from the DB and insert them into the dropdown user select
  const fetchUsers = async () => {
    try {
      const { data } = await axios.get(`${URL}/api/getUsersList/`);
      setUserOptions(data?.users.map(user => {
        return (<option key={user.username} value={user.username}>{user.username}</option>)}));
    } catch (err) {
      console.error(err);
      showError("Couldn't get users");
    }
  };

  // send the given information to get the relevant skill events to show
  const handleSubmit = async (event) => {
    event.preventDefault();
    // initializations
    const graphDataArr = [];
    setGraphData(graphDataArr);
    setSubmit(false);
    const form = event.target;
    try {
      // get the relevant skill events given the user and skill
      const data = await axios.get(`${URL}/api/getUserSkillEvents/?id=${form[0].value}&name=${form[1].value}`);
      const skill_events = data.data.skillEvents;
      const users_num = form[0].value === GraphFilterOptions.ALL_USERS ? form[0].length - 1 : 1;
      // group by date and count
      const skill_events_by_date = groupBy(skill_events, se => se.date);
      const dates = [...new Set(skill_events.map(se => se.date))];
      for (const date of dates) {
        const skill_events_in_date = skill_events_by_date.get(date);
        const pos = skill_events_in_date.filter((se) => se.type === "POS_FEEDBACK");
        const cue = skill_events_in_date.filter((se) => se.type === "CUE");
        let pos_num, cue_num;
        if (form[1].value === GraphFilterOptions.ALL_SKILLS) {
          const all_skill_events = getAllSkillEvents(pos, cue);
          pos_num = all_skill_events[0] / users_num;
          cue_num = all_skill_events[1] / users_num;
        } else {
          pos_num = pos.length / users_num;
          cue_num = cue.length / users_num;
        }
        graphDataArr.push({"name": date,
          "Positive Feedback": pos_num,
          "Cues": cue_num});
      }
      if (graphDataArr.length) {
        fillNullDates(graphDataArr, dates, GraphNames.PROGRESS);
      }
      setGraphData(graphDataArr);
      if (graphDataArr.length) {
        setShowMessage(false);
        setSubmit(true);
      } else {
        setShowMessage(true);
      }
      console.log("data.skillEvents", data.data.skillEvents);
    } catch (err) {
      console.error(err);
      showError("Couldn't show graph");
    }
  };

  // set the users list and skill list
  useEffect(() => {
    fetchSkills();
    fetchUsers();
  }, []);

  // hide graph after filter parameter change
  useEffect(() => {
    setSubmit(false);
  }, [user, skill]);

  return (
    <div>
      <form onSubmit={handleSubmit} className="flexbox-container">
        <div className="filter-item">
          <label className="filter-bar">Select user:</label>
          <select className="rectangle-object" name="selected_user" onChange={e => setUser(e.target.value)}>
            <option value={GraphFilterOptions.ALL_USERS}>- {GraphFilterOptions.ALL_USERS} -</option>
            { userOptions }
          </select>
        </div>
        <div className="filter-item">
          <label className="filter-bar">Select skill:</label>
          <select className="rectangle-object" name="selected_skill" onChange={e => setSkill(e.target.value)}>
            <option value={GraphFilterOptions.ALL_SKILLS}>- {GraphFilterOptions.ALL_SKILLS} -</option>
            { skillOptions }
          </select>
        </div>
        <button className="show-graph" type="submit">Show skill progress graph</button>
      </form>
      <Graph graphData={graphData} lines={lines} submit={submit} showMessage={showMessage} />
    </div>
  );
};

export default ProgressDisplay;
