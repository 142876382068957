//packages
import React from "react";
//style
import '../../style/convInfoPopup.css';
//tools
import { vh, vw } from "../../tools/screen"
import { Dialog } from "@mui/material";


const NoTokens = ({ close, open }) => {

    return (
        <Dialog
            open={open}
            PaperProps={{
                style: {
                    height: "fit-content",
                    display: "flex",
                    flexDirection: "column",
                    height: "70vh",
                    width: "65vw",
                    backgroundColor: "white",
                    borderRadius: "1vh",
                    maxHeight: "unset",
                    maxWidth: "unset",
                    overflow: "hidden"
                },
            }}
        >
                <div className="exitInfo" onClick={() => { close() }}>
                    <img src="/images/x_button.png" alt="back" />
                </div>
                <div className="homeInfoDiv" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h2 style={{ marginTop: vh(15), alignSelf: 'center', fontSize: vh(5), fontWeight: '800' }}>Your free trial with Arrows has ended...</h2>
                    {/* <h2 style={{ marginTop: vh(2), alignSelf: 'center', fontSize: vh(3), fontWeight: '600' }}>We would love to hear about your experience: please fill put the following form:</h2>
                    <a style={{ fontSize: vh(2.5), fontWeight: '400', color: '#262D3B' }} href="mailto:info@ar-rows.com">info@ar-rows.com</a> */}
                    <h2 style={{ marginTop: vh(5), alignSelf: 'center', fontSize: vh(3.5), fontWeight: '600' }}>Want to keep going?</h2>
                    <div style={{ display: 'flex', flexDirection: "row" }}> <h2 style={{ alignSelf: 'center', fontSize: vh(3.5), fontWeight: '400', marginRight: vw(.5) }}> contact us at: </h2><a style={{ fontSize: vh(3.5), fontWeight: '400', color: '#262D3B' }} href="mailto:info@ar-rows.com">info@ar-rows.com</a></div>

                </div>
                </Dialog>
    );
};

export default NoTokens;
