
import React from 'react';
import PageHeader from '../components/header';
import "../style/underMaintenance.css"
import { vw, vh } from '../tools/screen';


const UnderMaintenance = () => {
    return (
        <div style={{ width: vw(100), height: vh(100), overflow: 'hidden' }}>
            <PageHeader />
            <div className='maintenanceContainer'>

                <div className='maintenanceTitle'>Ar-rows is under maintenance at the moment...</div>
                <div className='maintenanceIcon'>

                    <img src="/images/support.png" alt="tools" />
                </div>
                <div className='maintenanceTextWrap'>

                    <h2 >We are working on improving and expanding the conversations, cues and feedbacks</h2>
                    <h2 >Thank you for your patience, the course will be back up shortly</h2>

                    <div className='maintenanceTextS'>
                        <h3>Need help? contact us: </h3>
                        <a href="mailto:info@ar-rows.com"> info@ar-rows.com</a>
                    </div>

                </div>
            </div>
        </div>)

};

export default UnderMaintenance;
