import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { URL } from "../../../tools/url";
import "../../../style/llmManagement.css"
import useErrorPopup from "../../../tools/hooks/showError";


const AddModelPopup = ({ updateTree, showPopup }) => {
  const [provider, setProvider] = useState("");
  const [providerList, setProviderList] = useState([]);
  const [modelFamily, setModelFamily] = useState("");
  const [modelFamilyList, setModelFamilyList] = useState([]);
  const [modelVersion, setModelVersion] = useState("");
  const [newProvider, setNewProvider] = useState("");
  const [newClass, setNewClass] = useState("");
  const [importCode, setImportCode] = useState("");
  const [newModelFamily, setNewModelFamily] = useState("");
  const [inputCost, setInputCost] = useState("");
  const [outputCost, setOutputCost] = useState("");
  const [addButtonDisabled, setAddButtonDisabled] = useState(true);
  const [selectDisabled, setSelectDisabled] = useState(true);
  const [showError, ] = useErrorPopup();
  const pageRef = useRef(null);

  // get all the providers from the DB
  const fetchProviders = async () => {
    try {
      const { data } = await axios.get(`${URL}/api/get_llm_provider_list/`);
      setProviderList(data?.providers.map(provider => {
        return (<option key={provider.name} value={provider.name}>{provider.name}</option>)}));
    } catch (err) {
      console.error(err);
      showError("Couldn't get providers");
    }
  };

  // get all the model families from the DB
  const fetchModelFamilies = async () => {
    try {
      if (provider !== "newProvider") {
        const { data } = await axios.get(`${URL}/api/get_llm_model_family_list/?parent_provider=${provider}`);
        setModelFamilyList(data?.model_families.map(model_family => {
          return (<option key={model_family.name} value={model_family.name}>{model_family.name}</option>)}));
      } else {
        setModelFamilyList([]);
      }
    } catch (err) {
      console.error(err);
      showError("Couldn't get model families");
    }
  };

  // add the new model and his parents (if there are any new parents) to the DB
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const modelVersionName = event.target.modelVersion.value;
      let providerName = provider;
      let modelFamilyName = modelFamily;
      if (provider === "newProvider") {
        providerName = event.target.providerName.value;
        setProvider(providerName);
        const codeLine = event.target.importCode.value;
        const providerClassName = event.target.providerClassName.value;
        await axios.get(`${URL}/api/add_llm_model/?llm_type=provider&llm_name=${providerName}&import_code_line=${codeLine}&llm_class_name=${providerClassName}`);
      }
      if (modelFamily === "newModelFamily") {
        modelFamilyName = event.target.modelFamilyName.value;
        setModelFamily(modelFamilyName)
        const inputCost = event.target.inputCost.value;
        const outputCost = event.target.outputCost.value;
        await axios.get(`${URL}/api/add_llm_model/?llm_type=model_family&llm_name=${modelFamilyName}&input_cost_per_million_tokens=${inputCost}&output_cost_per_million_tokens=${outputCost}&parent_provider=${providerName}`);
      }
      await axios.get(`${URL}/api/add_llm_model/?llm_type=model_version&llm_name=${modelVersionName}&parent_model_family=${modelFamilyName}`);
      updateTree();
      event.target.reset();
      showPopup(false);
    } catch (err) {
      console.error(err);
      showError("Couldn't show graph");
    }
  }

  // get the providers list and scroll up on popup load
  useEffect(() => {
    fetchProviders();
    pageRef?.current?.scrollIntoView({ block: 'end', behavior: "smooth" });
  }, []);

  // get the model families list if provider have been selected
  useEffect(() => {
    if (provider) {
      fetchModelFamilies();
      setSelectDisabled(false);
    }
  }, [provider]);

  // disable or enable the add button based on input
  useEffect(() => {
    if (modelVersion && ((provider && provider !== "newProvider" && modelFamily && modelFamily !== "newModelFamily") ||
        (provider === "newProvider" && newProvider && newClass && importCode && modelFamily === "newModelFamily" &&
          newModelFamily && inputCost && outputCost) ||
        (provider && provider !== "newProvider" && modelFamily === "newModelFamily" && newModelFamily && inputCost &&
          outputCost))) {
      setAddButtonDisabled(false);
    } else {
      setAddButtonDisabled(true);
    }
  }, [provider, modelFamily, modelVersion, newProvider, newClass, importCode, newModelFamily, inputCost, outputCost]);

  return (
    <div className="addModelPopup" ref={pageRef}>
      <h2 className="addModelHeader">Add new model</h2>
      <form onSubmit={handleSubmit} className="addModelForm">
        <div className="labelInput">
          <label className="addModelLabel">Select parent provider:</label>
          <select className="dropdownSelect" name="selectedProvider" onChange={(e) => { setProvider(e.target.value);
                                                                                        setModelFamily("");
                                                                                        }}>
            <option value="placeholder" selected={true} disabled={true}>- please select a provider -</option>
            { providerList }
            <option value="newProvider">- new provider -</option>
          </select>
        </div>
        { provider === "newProvider" ? (
          <>
            <div className="labelInput">
              <label className="addModelLabel">Provider name:</label>
              <input onChange={(e) => setNewProvider(e.target.value)} type="text" className="addModelInput"
                      name="providerName" required />
            </div>
            <div className="labelInput">
              <label className="addModelLabel">Class name:</label>
              <input onChange={(e) => setNewClass(e.target.value)} type="text" className="addModelInput"
                      name="providerClassName" required />
            </div>
            <div className="labelInput">
              <label className="addModelLabel">Import code line:</label>
              <input onChange={(e) => setImportCode(e.target.value)} type="text" className="addModelInput"
                      name="importCode" required />
            </div>
          </>
          ) : ( <></> ) }
        <div className="labelInput">
        <label className="addModelLabel">Select parent model family:</label>
          <select className="dropdownSelect" name="selectedModelFamily" disabled={selectDisabled}
                  onChange={e => setModelFamily(e.target.value)} value={modelFamily}>
            <option value="" selected={true} disabled={true}>- please select a model family -</option>
            { modelFamilyList }
            <option value="newModelFamily">- new model family -</option>
          </select>
        </div>
        { modelFamily === "newModelFamily" ? (
          <>
            <div className="labelInput">
              <label className="addModelLabel">Model family name:</label>
              <input onChange={(e) => setNewModelFamily(e.target.value)} type="text" className="addModelInput"
                      name="modelFamilyName" required />
            </div>
            <div className="labelInput">
              <label className="addModelLabel">Input cost per million tokens:</label>
              <input onChange={(e) => setInputCost(e.target.value)} type="number" step="any" className="addModelInput"
                      name="inputCost" required />
            </div>
            <div className="labelInput">
              <label className="addModelLabel">Output cost per million tokens:</label>
              <input onChange={(e) => setOutputCost(e.target.value)} type="number" step="any" className="addModelInput"
                      name="outputCost" required />
            </div>
          </>
          ) : ( <></> ) }
        <div className="labelInput">
          <label className="addModelLabel">Model version:</label>
          <input onChange={(e) => setModelVersion(e.target.value)} type="text" className="addModelInput"
                  name="modelVersion" placeholder="Enter model version" required />
        </div>
        <div className="llmButtons">
          <button className="llmButtonRed" onClick={(e) => showPopup(false)}>Cancel</button>
          <button className="llmButton" type="submit" disabled={addButtonDisabled}>Add model</button>
        </div>
      </form>
    </div>
  );
};


export default AddModelPopup;
