import * as React from 'react';
import AdminPage from "../components/admin/AdminPage";
import ProgressDisplay from "../components/admin/KPIs/ProgressDisplay";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import CustomTabPanel from "../components/admin/KPIs/CustomTabPanel.jsx";
import ConversationKPIs from "../components/admin/KPIs/ConversationKPIs.jsx";
import ConversationTurnsKPIs from "../components/admin/KPIs/ConversationTurnsKPIs.jsx";


const KPIs = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const props = (index) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
      style: { color: "black", fontSize: 16, textTransform: "none"},
    };
  };

  return (
    <AdminPage currentLocation="KPIs">
      <div className="prompt-generation-container">
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', marginRight: 25 }}>
            <Tabs value={value} onChange={handleChange} aria-label="statistics tabs"
                  TabIndicatorProps={{ style: { backgroundColor: "#0ae5a170" } }}>
              <Tab label="Skill KPIs" {...props(0)} />
              <Tab label="Conversation KPIs" {...props(1)} />
              <Tab label="Conversation Turns KPIs" {...props(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <ProgressDisplay />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <ConversationKPIs />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <ConversationTurnsKPIs />
          </CustomTabPanel>
        </Box>
      </div>
    </AdminPage>
  );
};

export default KPIs;
