//packages
import React from "react";
//style
import '../style/button.css'

const Button = ({ text, style, iconstyle, textStyle, icon, alt, onClick }) => {

    return (
        <div className="buttonComponent" style={style} onClick={onClick}>
            <h2 style={textStyle}>{text}</h2>
            {icon ? <img
                style={iconstyle}
                src={icon}
                alt={alt}
            /> : <></>}
        </div>

    );
};

export default Button;
