import { useState } from "react";
import { Crop75 } from "@mui/icons-material";
import useErrorPopup from "../../../../tools/hooks/showError";
import ScenarioStateInList from "./ScenarioStateInList";
import axios from "axios";
import { URL } from "../../../../tools/url";

const ScenarioStateList = ({
  scenario,
  setScenario,
  save,
  loading,
  saving,
}) => {
  const [editingNew, setEditingNew] = useState(false);
  const [savingNew, setSavingNew] = useState(false);
  const [newScenarioStateName, setNewScenarioStateName] = useState("");
  const [showError, ErrorPopupComponent] = useErrorPopup();

  const updateScenarioState = (
    scenarioStateData,
    isNew = false,
    deleteScenarioState = false
  ) => {
    setScenario({
      ...scenario,
      states: isNew
        ? [...scenario.states, scenarioStateData]
        : deleteScenarioState
        ? scenario.states.filter((s) => s.id !== scenarioStateData.id)
        : scenario.states.map((s) => {
            if (s.id === scenarioStateData.id) {
              return scenarioStateData;
            }
            return s;
          }),
    });
  };

  const saveScenarioState = async (scenarioStateData, isNew = false) => {
    try {
      const { data } = await axios.post(`${URL}/api/ceSaveScenarioState/`, {
        ...scenarioStateData,
      });
      updateScenarioState(data, isNew);
    } catch (err) {
      console.error(err);
      showError("Couldn't save scenario state");
    }
  };

  const saveNewScenarioState = () => {
    setSavingNew(true);
    saveScenarioState(
      {
        parent_scenario_id: scenario.id,
        name: newScenarioStateName,
        state_conditions: [],
      },
      true
    );
    setEditingNew(false);
    setSavingNew(false);
    setNewScenarioStateName("");
  };

  return (
    <>
      <div className="prompt-generation-section">
        <h2 style={{ marginBottom: "10px" }}>Scenario states</h2>
        <div className="staff-course-list scenario-state-list">
          {scenario?.states ? (
            scenario.states.length ? (
              <>
                {scenario.states.map((scenarioState) => (
                  <ScenarioStateInList
                    key={scenarioState.id}
                    scenarioState={scenarioState}
                    updateScenarioState={updateScenarioState}
                    saveScenarioState={saveScenarioState}
                    allScenarioConditions={scenario.conditions}
                    saving={saving}
                  />
                ))}
              </>
            ) : (
              <div className="staff-course-in-list">
                No scenario states available
              </div>
            )
          ) : (
            <div className="staff-course-in-list">
              <div className="loader prompt-generation-loader">
                <div id="bar1" className="bar"></div>
                <div id="bar2" className="bar"></div>
                <div id="bar3" className="bar"></div>
              </div>
            </div>
          )}
          <div
            className="staff-course-in-list"
            style={{
              margin: "10px 0",
              background: "#fec195",
              display: "flex",
              alignItems: "center",
              height: "50px",
              padding: "0 15px",
            }}
          >
            {editingNew ? (
              <div
                className="staff-course-in-list-name"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Crop75 />
                <input
                  placeholder="Enter scenario state name..."
                  value={newScenarioStateName}
                  onChange={(e) => setNewScenarioStateName(e.target.value)}
                  style={{ width: "600px", boxSizing: "border-box" }}
                  autoFocus
                ></input>
                {savingNew ? (
                  <div
                    style={{
                      width: "200px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className="loader prompt-generation-loader"
                      style={{ height: "35px" }}
                    >
                      <div id="bar1" className="bar"></div>
                      <div id="bar2" className="bar"></div>
                      <div id="bar3" className="bar"></div>
                    </div>
                  </div>
                ) : (
                  <>
                    <button
                      className="prompt-generation-button"
                      style={{
                        margin: "0 5px 0 0",
                        padding: "5px 10px",
                        background: false ? "#ccc" : "#0ae5a1",
                        border: "1px solid #282c34",
                        color: "#282c34",
                      }}
                      disabled={false}
                      onClick={saveNewScenarioState}
                    >
                      SAVE
                    </button>
                    <button
                      className="prompt-generation-button"
                      style={{
                        margin: 0,
                        padding: "5px 10px",
                        background: "#fa7d5f",
                        border: "1px solid #282c34",
                        color: "#282c34",
                      }}
                      onClick={() => {
                        setEditingNew(false);
                        setNewScenarioStateName("");
                      }}
                    >
                      CANCEL
                    </button>
                  </>
                )}
              </div>
            ) : (
              <button
                className="prompt-generation-button"
                style={{
                  margin: 0,
                  padding: "5px 10px",
                }}
                onClick={() => setEditingNew(true)}
              >
                ADD A NEW SCENARIO STATE
              </button>
            )}
          </div>
        </div>
      </div>
      {ErrorPopupComponent}
    </>
  );
};

export default ScenarioStateList;
