import { useEffect, useState } from "react";
import ListedInfoPage from "./ListedInfoPage";
import ListedScenario from "./ListedScenario";
import { Stepper, Step, StepContent, StepLabel } from "@mui/material";

const Unit = ({
  topic,
  devMode,
  navigate,
  index,
  userPlacement,
  setUserPlacement,
}) => {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    setActiveStep(index === userPlacement[0] ? userPlacement[1] : 0);
  }, [index, userPlacement]);

  return (
    <>
      <StepLabel
        sx={{
          "& .MuiStepLabel-iconContainer": {
            p: 0,
          },
          height: "fit-content",
        }}
        StepIconComponent={() =>
          topic.status === "done" ? (
            <img
              src="/images/topicNumN.png"
              alt="number decore"
              className="topicNum"
            />
          ) : (
            <div
              style={{ backgroundColor: "#262D3B", zIndex: 3 }}
              className="topicNum"
            ></div>
          )
        }
      >
        <h3 className="topicTitle">{topic.topicName}</h3>
      </StepLabel>
      <StepContent>
        {topic?.items?.length ? (
          <Stepper
            orientation="vertical"
            activeStep={activeStep}
            sx={{ ml: "5vh", pl: 0, mt: "2vh" }}
          >
            {topic.items.map((item, itemIndex) => {
              return (
                <Step
                  completed={item.status === "done"}
                  key={itemIndex}
                  sx={{ p: 0 }}
                >
                  {item.type === "Conversation" ? (
                    <ListedScenario
                      conversation={item}
                      devMode={devMode}
                      navigate={navigate}
                      onClick={() => setUserPlacement([index, itemIndex])}
                      isActive={activeStep === itemIndex}
                    />
                  ) : (
                    <ListedInfoPage
                      key={itemIndex}
                      infoPage={item}
                      navigate={navigate}
                      onClick={() => setUserPlacement([index, itemIndex])}
                      isActive={activeStep === itemIndex}
                    />
                  )}
                </Step>
              );
            })}
          </Stepper>
        ) : (
          <></>
        )}
      </StepContent>
    </>
  );
};

export default Unit;
